import { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button } from 'components/utilities/components/Button';
import Content from 'components/utilities/components/Content';
import { theme } from 'components/utilities/utils';

const Section = styled.section`
  text-align: center;
  padding: 0;
  background: white;
  background-repeat: repeat-x;
  max-width: 1280px;
  border-left: 5px solid ${(props) => props.borderLeft || 'transparent'};
  border-right: 5px solid ${(props) => props.borderRight || 'transparent'};
  box-sizing: border-box;
  margin: o auto;
  @media screen and (max-width: 1280px) {
    max-width: 960px;
  }
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px 40px 150px;
  box-sizing: border-box;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
    padding: 0px 40px 120px;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 60px;
  }
`;

const SectionImage = styled.span`
  background: url(${(props) => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-width: 280px;
  height: 380px;
  margin-right: -50px;
  color: ${theme.colors.black};
  font-family: 'PT Sans';
  display: flex;
  flex: 1;
  h2,
  h4 {
    box-sizing: border-box;
    text-align: left;
    margin-bottom: 0;
  }
  @media screen and (max-width: 1280px) {
    margin-right: 0;
    margin-bottom: 20px;
    min-height: 330px;
    margin-bottom: 0px;
    background-position: left;
  }
`;

const SectionParagraph = styled.span`
  background-color: #f7f7f7cc;
  opacity: 0.95;
  color: ${theme.colors.black};
  text-align: left;
  font-family: 'PT Sans';
  padding: 30px 20px 105px 10px;
  border-radius: 1px;
  margin-left: 10px;
  z-index: 1;
  max-width: 780px;
  display: flex;
  flex: 2;

  @media screen and (max-width: 1280px) {
    width: 100%;
    margin-left: 0;
    padding: 20px;
  }
`;

const SectionText = withReveal(
  SectionParagraph,
  <Fade cascade right distance="10%" fraction={0.2} />,
);

const SectionQuote = styled.div`
  padding: 20px 40px 20px 50px;
  margin: 20px auto;
  list-style: none;
  h5 {
    font-size: 16px;
    font-weight: 600;
  }
  span {
    font-style: italic;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 28px;
    &:before {
      content: '';
      background-image: url('/images/rings.png');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: -20px;
      top: -12px;
      height: 50px;
      width: 60px;
    }
  }

  li {
    margin-bottom: 20px;
    list-style: disc;
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 600;
    color: ${theme.colors.black};
  }
`;

const Form = styled.div`
  display: flex;
  position: relative;
  margin: 30px auto 0;
  box-sizing: border-box;
  height: 65px;
  font-style: initial;

  label,
  article {
    display: flex;
    margin-top: 0;
    margin-right: -20px;
  }

  article div {
    height: 65px;
  }

  input {
    height: 45px;
    padding: 10px 20px;
    color: ${(props) => props.color || theme.colors.white5};
    background: ${theme.colors.white8};
    box-shadow: 1px 1px 1px darkgrey;
    font-weight: 600;
    border-radius: 0px 0px 0px 3px;
    width: 360px;
    margin-left: -130px;

    ${down('sm')} {
      width: 200px;
      margin-left: initial;
    }
  }
`;

export default function Signups(props) {
  const { content } = useContext(AppContext);
  const { pageName } = props;
  const pageObject = content[pageName];
  const sectionObject = pageObject.Signups;

  return (
    <Content content={sectionObject}>
      <Section
        borderLeft={sectionObject.LeftBorder}
        borderRight={sectionObject.RightBorder}
      >
        <SectionContent>
          <SectionImage image={sectionObject.Image} />
          <SectionText color={props.color}>
            <SectionQuote>
              <span>{sectionObject.Quote}</span>
              <h5>{sectionObject.Desc}</h5>
              <Form color={props.color}>
                <Button
                  fontSize="17px"
                  letterSpacing="1px"
                  className="outline"
                  textcolor={theme.colors.black}
                  background={theme.colors.white}
                  text="Visit us on LinkedIn"
                  onClick={() => window.open(sectionObject.LinkedIn, '_blank')}
                />
              </Form>
            </SectionQuote>
          </SectionText>
        </SectionContent>
      </Section>
    </Content>
  );
}
