import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
// Sentry packages
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Roots from 'roots';
import './index.css';
import { unregister } from './registerServiceWorker';

// THIS SITE IS SERVED VIA REACT ROUTER 6.
// ROUTES ARE DEFINED in ROOTS.jS! ROOTERS!

// WELCOME TO PARKDALE CENTRE FOR INNOVATION's WEBSITE.
// MADE WITH LOVE FOR OUR PEOPLE AROUND THE WORLD.

unregister();

// Register Sentry only if environment variable with DSN key is present
if (process.env.REACT_APP_SENTRY_DSN)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    release: '0.2.0',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Roots />);
