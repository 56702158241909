const SHEETSURL = process.env.REACT_APP_SHEETS_URL;

// Dynamically check for all environment variables that start with REACT_APP_WEB_SHEET_IDS and map them to an array of objects

const WEBSHEETIDS = Object.keys(process.env)
  .filter((envVar) => envVar.startsWith('REACT_APP_WEB_SHEET_IDS'))
  .map((envVar) => {
    const language = envVar.slice(-2).toLowerCase();
    const sheets = process.env[envVar];
    // console.log(sheets); // Log the sheets
    return {
      language,
      sheets,
    };
  });

const sheetProperties = {
  contentSheet: '726389919',
  programsSheet: '61980625',
  portraitsSheet: '628941449',
  deployStatusSheet: '177181379',
};

const REQUESTCODE = process.env.REACT_APP_REQUEST_CODE;

async function getGSheetJson(WEBSHEETID, SheetGID, QUERY) {
  const encodedQuery = encodeURI(QUERY);

  const response = await fetch(
    `${SHEETSURL + WEBSHEETID + REQUESTCODE + encodedQuery}&gid=${SheetGID}`,
  );
  const text = await response.text();
  const jsonResponse = JSON.parse(text.slice(47, -2));

  return jsonResponse;
}

async function fetchContentSpreadsheet(WEBSHEETID, QUERY, SheetGID) {
  const jsonResponse = await getGSheetJson(WEBSHEETID, SheetGID, QUERY);
  const { rows } = jsonResponse.table;
  let content = {};
  const pageGuide = {}; /// pageGuide links column numbers with column/page names
  let section;
  let contentArea;
  let pageName;

  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    /// row index === top-down pointer
    const rowData = rows[rowIndex].c;

    for (let colIndex = 0; colIndex < rowData.length; colIndex++) {
      /// /col index === left-right pointer
      const cellContent = rowData[colIndex] && rowData[colIndex].v;
      if (rowIndex === 0) {
        /// first row has column names
        if (cellContent) {
          content[cellContent] = {};
          pageGuide[colIndex] = cellContent; /// populate page guide
        }
      } else {
        pageName = pageGuide[colIndex] || pageName;
        switch (colIndex) {
          case 0: /// /Column A, contains sections
            section = cellContent || section; /// if on a new section value, get it, otherwise, keep the originial one
            break;
          case 1: /// /Column B, contains content areas
            contentArea = cellContent || contentArea;
            break;
          default:
            content = {
              ...content,
              [pageName]: {
                ...content[pageName],
                [section]: {
                  ...content[pageName][section],
                  [contentArea]: cellContent,
                },
              },
            };
            break;
        }
      }
    }
  }

  return content;
}

async function fetchProgramsSpreadsheet(WEBSHEETID, QUERY, SheetGID) {
  const jsonResponse = await getGSheetJson(WEBSHEETID, SheetGID, QUERY);
  const { rows } = jsonResponse.table;
  let content = {};
  const programGuide = {}; // pageGuide links column numbers with column/page names
  let contentArea;
  let programName;

  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    // Row index === top-down pointer
    const rowData = rows[rowIndex].c;

    for (let colIndex = 0; colIndex < rowData.length; colIndex++) {
      // Col index === left-right pointer
      const cellContent = rowData[colIndex] && rowData[colIndex].v;

      // Check if the cell is not empty before processing
      if (cellContent) {
        if (rowIndex === 0) {
          // First row has column names
          if (colIndex === 0) {
            programGuide[colIndex] = cellContent; // Populate page guide
          } else {
            content[cellContent] = {};
            programGuide[colIndex] = cellContent; // Populate page guide
          }
        } else {
          programName = programGuide[colIndex] || programName;
          switch (colIndex) {
            case 0: // Column A, contains program names
              programName = cellContent;
              break;
            case 1: // Column B, contains content areas
              contentArea = cellContent.toLowerCase().replace(' ', ''); // Convert to code-friendly data
              break;
            default:
              // Update content only if programName and contentArea are defined
              if (programName && contentArea) {
                content = {
                  ...content,
                  [programName]: {
                    ...content[programName],
                    [contentArea]: cellContent,
                  },
                };
              }
              break;
          }
        }
      }
    }
  }
  return content;
}

async function fetchDeployStatusSpreadsheet(WEBSHEETID, QUERY, SheetGID) {
  const jsonResponse = await getGSheetJson(WEBSHEETID, SheetGID, QUERY);
  const { cols } = jsonResponse.table;
  if (cols?.length) {
    const status = cols[1].label?.includes('Deployed')
      ? 'Deployed'
      : 'Ready to deploy';
    return status;
  }
  return '';
}

async function getContentsFromGoogle() {
  const QUERY = 'SELECT * offset 1';

  // Map each language configuration to a promise that resolves to its full content
  const contentPromises = WEBSHEETIDS.map(async ({ language, sheets }) => {
    // Fetch all data in parallel for each language configuration
    const [content, programs, portraits, deployStatus] = await Promise.all([
      fetchContentSpreadsheet(sheets, QUERY, sheetProperties.contentSheet),
      fetchProgramsSpreadsheet(sheets, QUERY, sheetProperties.programsSheet),
      fetchProgramsSpreadsheet(sheets, QUERY, sheetProperties.portraitsSheet),
      fetchDeployStatusSpreadsheet(
        sheets,
        QUERY,
        sheetProperties.deployStatusSheet,
      ),
    ]);

    // Return the structured data for each language
    return { language, content, programs, portraits, deployStatus };
  });

  // Wait for all language content promises to resolve
  const contents = await Promise.all(contentPromises);

  return contents;
}

export { getContentsFromGoogle, getGSheetJson };
