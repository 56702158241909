import { Fragment } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// Site Imports
import App from 'App';
import { ThemeProvider } from 'styled-components';
import { DEFAULT_LANGUAGE, LOCALES } from 'constants/locales';
// AF Imports
import AboutUsPage from 'components/pages/about/about';
import GetInvolvedPage from 'components/pages/about/getinvolved';
import GettingStartedPage from 'components/pages/about/gettingstarted';
import ParticipatePage from 'components/pages/about/participate';
import Blog from 'components/pages/blog/blog';
import C1464 from 'components/pages/c1464/c1464';
import Project1464 from 'components/pages/c1464/c1464project';
import DeployContentV5 from 'components/pages/deploy-content-v5/deploy-content-v5';
import FundingPage from 'components/pages/funding/funding';
import RaisePage from 'components/pages/funding/raise';
import FutureSkills from 'components/pages/futureskills/futureskills';
import HomePage from 'components/pages/homepage/homepage';
import KidsInnovate from 'components/pages/programs/kidsinnovate';
import Media from 'components/pages/programs/media';
import StartupsPage from 'components/pages/programs/startups';
import WomenFoundersPage from 'components/pages/programs/womenfounders';
import { breakpoints, theme } from 'components/utilities/utils';

function Roots() {
  const getLocaleLink = (locale, link) => `${locale}/${link}`;

  return (
    <BrowserRouter>
      <ThemeProvider theme={{ ...theme, ...breakpoints }}>
        <App>
          <Routes>
            {LOCALES.map((locale) => (
              <Fragment key={locale}>
                <Route exact path={`/${locale}`} element={<HomePage />} />
                <Route path={getLocaleLink(locale, 'programs')}>
                  <Route index element={<StartupsPage />} />
                  <Route path="womenfounders" element={<WomenFoundersPage />} />
                  <Route
                    path="womeninleadership"
                    element={<WomenFoundersPage />}
                  />
                  <Route path="earlystagestartups" element={<StartupsPage />} />
                  <Route path="startups" element={<StartupsPage />} />
                  <Route path="media" element={<Media />} />
                  <Route path="kids" element={<KidsInnovate />} />
                  <Route path="education" element={<KidsInnovate />} />
                </Route>
                <Route path="education" element={<KidsInnovate />} />
                <Route path={getLocaleLink(locale, '1464')}>
                  <Route index element={<C1464 />} />
                  {/* <Route path="co-working" element={<C1464 />} /> */}
                  {/* <Route path="space-booking" element={<C1464 />} /> */}
                  {/* <Route path="events" element={<C1464 />} /> */}
                  <Route path="1464" element={<Project1464 />} />
                  {/* <Route path="community" element={<C1464 />} /> */}
                </Route>
                <Route path="community" element={<C1464 />} />

                <Route path={getLocaleLink(locale, 'about')}>
                  <Route index element={<AboutUsPage />} />
                  <Route path="about-us" element={<AboutUsPage />} />
                  {/* <Route
                    path="getting-started"
                    element={<GettingStartedPage />}
                  /> */}
                </Route>

                <Route path={getLocaleLink(locale, 'getting-started')}>
                  <Route index element={<GettingStartedPage />} />
                  <Route
                    path="getting-started"
                    element={<GettingStartedPage />}
                  />
                  <Route path="participate" element={<ParticipatePage />} />
                  <Route
                    path="ecosystem-support"
                    element={<GetInvolvedPage />}
                  />
                </Route>
                <Route path={getLocaleLink(locale, 'funding')}>
                  <Route index element={<FundingPage />} />
                  <Route path="raise" element={<RaisePage />} />
                  <Route path="funding" element={<FundingPage />} />
                </Route>
                <Route path={getLocaleLink(locale, 'futureskills')}>
                  <Route index element={<FutureSkills />} />
                </Route>
                <Route path={getLocaleLink(locale, 'blog')} element={<Blog />}>
                  <Route path=":postId" element={<Blog />} />
                </Route>
                <Route
                  path="/deploy-content-v5"
                  element={<DeployContentV5 />}
                />
                <Route
                  path="*"
                  element={
                    <Navigate
                      replace
                      to={`/${
                        localStorage.getItem('locale') || DEFAULT_LANGUAGE
                      }`}
                    />
                  }
                />
              </Fragment>
            ))}
            {/* Special Routes for gaming */}
            <Route
              path="/gaming"
              element={
                <Navigate
                  replace
                  to={`/${
                    localStorage.getItem('locale') || DEFAULT_LANGUAGE
                  }/programs/kids`}
                />
              }
            />
          </Routes>
        </App>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default Roots;
