import { useMemo } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import styled from 'styled-components';
import ProfileCard from 'components/pages/about/profilecard';
import Content from 'components/utilities/components/Content';
import { Section } from 'components/utilities/components/SectionComponent';
import { SectionHeading } from 'components/utilities/components/SectionHeading';

const ProfileSection = styled(Section)`
  width: 100%;
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  background: ${(props) => props.background || 'white'};
  padding: ${(props) => props.padding || '80px 20px 40px'};
  margin: ${(props) => props.margin};
  align-items: ${(props) => props.align};
  border-left: ${(props) => props.borderLeft};
  max-width: 1280px;
  box-sizing: border-box;
  &.advisors {
    .image {
      max-width: 150px;
      height: 150px !important;
    }
  }
  @media screen and (max-width: 640px) {
    padding: ${(props) => props.paddingFor640Px};
    margin-top: ${(props) => props.marginFor640px};
  }
  h2 {
    margin: ${(props) => props.h2Margin};
    background: ${(props) => props.h2Background || 'none'};
  }
  h3 {
    margin: ${(props) => props.h3Margin};
    padding: ${(props) => props.h3Padding};
  }
`;

const listItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: ${(props) => props.flexBasis};
  flex-wrap: wrap;
  justify-content: flex-start;
  padding:  ${(props) => props.padding || '20px 40px 0px'};
  margin:${(props) => props.margin};
  margin-top:${(props) => props.marginTop || '40px'} ;
  max-width: 1120px;
  width:${(props) => props.width} 
  &:nth-of-type(2) {
    padding-top: 0px;
  }
  @media screen and (max-width: 640px) {
    padding:${(props) => props.padding650Px} ;
  }
  @media screen and (max-width: 960px) {
    padding:${(props) => props.padding960px} ;
   }
`;

export default function Profile(props) {
  const { content, className, heading, type } = props;

  const ProfileList = useMemo(
    () =>
      props.withReveal
        ? withReveal(
            listItems,
            <Fade
              distance={props.withReveal.distance}
              fraction={props.withReveal.fraction}
            />,
          )
        : listItems,
    [props.withReveal],
  );

  return (
    <Content content={content}>
      <ProfileSection {...props} className={className || ''}>
        <SectionHeading
          title={heading.title}
          background={heading.background || 'white'}
          image={heading.image || 'advisory-img.png'}
          maxwidth={heading.maxwidth || '500px'}
        />
        <ProfileList {...props.list}>
          {content.map((member) => (
            <ProfileCard
              key={member.name}
              image={`/images/${member.image}`}
              subtitle={member.name}
              link={member.linkedin}
              linkText={member.linkedin && 'in'}
              paragraph={member.description}
              type={type}
            />
          ))}
        </ProfileList>
      </ProfileSection>
    </Content>
  );
}
