import { useContext, useMemo } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { BorderedListItem } from 'components/utilities/components/BorderedListItem';
import Content from 'components/utilities/components/Content';
import { Section } from 'components/utilities/components/SectionComponent';
import { SectionHeading } from 'components/utilities/components/SectionHeading';
import { theme } from 'components/utilities/utils';

const GridSection = styled(Section)`
  background-color: ${(props) => props.theme.colors.white};
  background-size: 55%;
  background-position-x: 160px;
  background-position-y: 130px;
  background-repeat: no-repeat;
  padding: 0px 0px 90px;
  margin: ${(props) => props.margin};
  h2 {
    margin-bottom: 40px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.black2};
    margin-top: 60px;
  }
  &.list {
    background-position-x: right;
    background-position-y: 160px;
    background-repeat: repeat-x;
  }
  ${down('sm')} {
    background-size: 100%;
  }
`;

const GridListContainer = styled.div`
  background: ${(props) => props.backgroundcolor};
  padding: 40px 20px;
  display: flex;
  flex-direction: row;
  ${down('md')} {
    flex-direction: column-reverse;
  }
`;

const GridListImage = styled.div`
  ${(props) =>
    props.backgroundimage && `background-image: url(${props.backgroundimage});`}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-width: 280px;
  min-height: 380px;
  ${down('md')} {
    flex-direction: column-reverse;
    padding: 20px;
    margin: 20px;
    box-sizing: border-box;
    width: 95%;
  }

  ${down('sm')} {
    padding: 30px;
    margin: 15px;
  }
`;

const GridList = withReveal(
  styled.div`
    background-position: right;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    content: 'auto';
    margin-top: 0px;
    padding: 5px 10px 0px;
    // on hover border 1px solid black
    &.list {
      display: flex;
      justify-content: center;
      max-width: 830px;
      .item-container {
        max-width: 620px;
        flex-direction: row;
      }
      .image,
      svg {
        background-position-x: left;
        margin-left: 5px;
      }
      h5,
      p {
        flex-direction: initial;
      }
      p {
        text-align: left !important;
      }
      .meta-data {
        flex: 1;
        margin-top: 15px;
      }
    }
    ${down('sm')} {
      padding: 0px 10px;
    }
  `,
  <Fade left distance="5%" fraction={0.2} />,
);

const GridListItem = styled(BorderedListItem)`
  display: flex;
  flex-direction: column;
  height: initial;
  padding: 20px 20px 0px 10px;
  max-width: 280px;
  box-sizing: border-box;
  margin: 0px;
  margin-right: -1px;
  text-align: right !important;
  margin: 5px;
  border-radius: 0px !important;
  border: none;
  box-shadow: 0px 2px 7px ${(props) => props.theme.colors.gray9};
  border-radius: 2px !important;
  border: 1px solid transparent;
  :hover {
    border: 1px solid black;
  }
  &.tall {
    .image {
      max-height: 120px;
    }
  }
  &.small {
    .image {
      max-height: 60px;
    }
  }

  &:first-child {
    border-radius: 3px;
  }
  h5 {
    flex-direction: row-reverse;
    font-weight: bold;
    color: ${(props) => props.color};
  }
  h6 {
    /* display: none; */
  }
  p {
    font-size: 13px;
    text-align: left;
  }
  .image {
    background-size: contain;
    background-position-x: right;
    background-position-y: center;
    width: 100%;
    max-height: 70px;
    margin-bottom: 15px;
    margin-bottom: 0px;
    padding: 10px;
    margin: 20px 0px 5px 80px;
    min-height: 50px;
    box-shadow: none;
  }

  ${down('lg')} {
    max-width: 100%;
    width: 100%;
    p {
      max-width: 80% !important;
      text-align: left;
    }
    h5 {
      max-width: 100%;
    }
    h6 {
      max-width: 80%;
    }
    .image {
      max-width: 80%;
    }
  }
`;

export default function BoxGrid(props) {
  const { content } = useContext(AppContext);
  const { pageName, size } = props;
  const boxes = useMemo(() => {
    const result = [];
    const boxgridObject = content[pageName].BoxGrid;
    if (!boxgridObject) return result;
    const itemNumber = parseInt(Object.keys(boxgridObject).length / 8, 10);

    for (let index = 1; index <= itemNumber; index++) {
      if (!boxgridObject[`title${index}`]) break;

      result.push({
        image: boxgridObject[`image${index}`],
        icon: boxgridObject[`icon${index}`],
        className: boxgridObject[`className${index}`],
        color: boxgridObject[`color${index}`],
        title: boxgridObject[`title${index}`],
        paragraph: boxgridObject[`paragraph${index}`],
        link: boxgridObject[`link${index}`],
        linktext: boxgridObject[`linktext${index}`],
      });
    }

    return result;
  }, [content, pageName]);

  return (
    <Content content={content[pageName].BoxGrid}>
      <GridSection className={size} margin={props.margin}>
        <SectionHeading
          {...props}
          title={content[pageName].BoxGrid.Title || ''}
          background={theme.colors.white}
          maxwidth="500px"
        />

        <GridListContainer
          backgroundcolor={content[pageName].BoxGrid.backgroundcolor}
        >
          <GridList className={size}>
            {boxes.map((item, idx) => (
              <GridListItem
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                image={item.image}
                icon={item.icon}
                title={item.title}
                color={item.color}
                paragraph={item.paragraph}
                background={props.background}
              />
            ))}
          </GridList>
          <GridListImage
            backgroundimage={content[pageName].BoxGrid.backgroundimage}
          />
        </GridListContainer>
      </GridSection>
    </Content>
  );
}
