import styled from 'styled-components';

const FullLogo = styled.img`
  float: 'left';
  height: inherit;
`;

export default function Logo(props) {
  return <FullLogo {...props} alt="Parkdale Centre" src={props.pageicon} />;
}
