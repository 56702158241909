import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListItemIcon as ListItemIconMui,
  ListItemText as ListItemTextMui,
  MenuItem as MenuItemMui,
  MenuList,
} from '@mui/material';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const GroupDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MenuItemsContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  border-radius: 3px;
  height: 100%;
  width: 100%;
`;

const MenuItemContainer = styled(MenuList)`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const MenuItem = styled(MenuItemMui)`
  padding: 10px 20px !important;
`;

const ListItemIcon = styled(ListItemIconMui)`
  min-width: 44px !important;
`;

const ListItemText = styled(ListItemTextMui)`
  padding-left: ${(props) => !props.isActive && '44px'};
  .MuiTypography-root {
    font-size: 16px;
    font-family: pt sans;
    line-height: 20px;
    font-weight: 700;
  }
`;

const SelectBox = styled.div`
  width: 283px;
  border-radius: 3px;
  padding-left: 11px;
  padding-right: 18px;
  padding: 9px 15px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0px 2px 4px  ${(props) => props.theme.colors.gray19});
  ${down('md')} {
    width: 180px;
  }
  ${down('sm')} {
    border-left: 4px solid ${(props) => props.theme.colors.black};
    width: 94px;
    padding: 8px 9px 8px 14px;
    margin-right: 10px;
    margin-left: 16px;
   }
  }
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 27px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${down('sm')} {
    line-height: 16px;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) => (props.open ? '343px' : '0px')};
  top: 92px;
  transition-property: all;
  width: 297px;
  box-shadow: 0px 0px 6px ${(props) => props.theme.colors.gray20};
  transition-duration: ${(props) => (props.open ? ' 0.2s' : ' 0.1s')};
  ${down('sm')} {
    top: 57px;
    right: 10px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.black};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

function GroupDropdown(props) {
  const { open, handleClick, options, value, handleOnChange } = props;

  return (
    <GroupDropdownContainer>
      <SelectBox onClick={handleClick}>
        <Title>{value}</Title>
        {open ? (
          <Icon icon="fa-caret-up" width={24} height={24} />
        ) : (
          <Icon icon="fa-caret-down" width={24} height={24} />
        )}
      </SelectBox>
      <Dropdown open={open}>
        <MenuItemsContainer>
          <MenuItemContainer>
            {options.map((item, index) => {
              const isActive = item.text === value;

              return (
                <MenuItem
                  key={index}
                  value={item.text}
                  dense
                  onClick={() => handleOnChange(item)}
                >
                  {isActive && (
                    <ListItemIcon>
                      <Icon icon="fa-circle" width={18} height={18} />
                    </ListItemIcon>
                  )}
                  <ListItemText isActive={isActive}>{item.text}</ListItemText>
                </MenuItem>
              );
            })}
          </MenuItemContainer>
        </MenuItemsContainer>
      </Dropdown>
    </GroupDropdownContainer>
  );
}

export default GroupDropdown;
