import { useContext, useMemo } from 'react';
import AppContext from 'AppContext';
import Profile from 'components/base/profile/profile';

export function Board(props) {
  const { portraits } = useContext(AppContext);
  const { pageName } = props;
  const boardMembers = useMemo(() => {
    const boardMembersObj = portraits[pageName];

    if (!boardMembersObj) return [];

    const result = [];
    const itemNumber = parseInt(Object.keys(boardMembersObj).length / 4, 10);

    for (let index = 1; index <= itemNumber; index++) {
      if (!boardMembersObj[`name${index}`]) break;
      if (!boardMembersObj[`description${index}`]) break;

      result.push({
        image: boardMembersObj[`image${index}`],
        name: boardMembersObj[`name${index}`],
        description: boardMembersObj[`description${index}`],
        linkedin: boardMembersObj[`linkedin${index}`],
      });
    }

    return result;
  }, [portraits, pageName]);

  return (
    <Profile
      display="flex"
      direction="column"
      justify="flex-start"
      margin="0px"
      padding="90px 0px 40px 0px"
      align="center"
      h2Margin="0px auto 80px"
      h3Margin="10px 0px 0px 0px"
      h3Padding="0px 0px 0px 0px"
      marginFor640px="-40px"
      heading={{
        title: 'Board of Directors',
      }}
      list={{
        flexBasis: 'auto',
        marginTop: '0px',
        padding: '0px 40px',
        margin: '0 auto',
        padding960px: '0px',
      }}
      content={boardMembers}
      type="large"
    />
  );
}
