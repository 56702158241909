import { useEffect, useMemo, useState } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import AppContext from 'AppContext';
import BottomBanner from 'components/utilities/components/BottomBanner';
import { MobileNavSlider } from 'components/utilities/components/MobileNavSlider';
import { getLanguage, getPageContents } from 'components/utilities/utils';
import './App.css';

library.add(fab, fas);

// TODO take the menu state out of here and into it's own thing
export default function App({ children }) {
  const [contents, setContents] = useState([]);
  const [timestamp, setTimestamp] = useState();
  const [content, setContent] = useState();
  const [programs, setPrograms] = useState();
  const [portraits, setPortraits] = useState();
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  const [language, setLanguage] = useState(getLanguage());

  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  });

  useEffect(() => {
    getPageContents().then((pageContents) => {
      setContents(pageContents);
    });
  }, []);

  useEffect(() => {
    if (location.state?.refresh) {
      getPageContents().then((pageContents) => {
        setContents(pageContents);
      });
    }
  }, [location.state]);

  useEffect(() => {
    /// pre-existing useEffect hook, not part of the scroll-to-anchor function.
    setMenuOpen(false);
    const { hash } = location;

    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [location]); // do this on route change

  useEffect(() => {
    if (contents.length > 0) {
      const stateContents = contents?.filter(
        (_content) => _content.language === language,
      );
      const stateContent = stateContents[0];
      setContent(stateContent.content);
      setPrograms(stateContent.programs);
      setPortraits(stateContent.portraits);
      setTimestamp(stateContent?.timestamp);
    }
  }, [language, contents]);

  const contextValue = useMemo(
    () => ({
      contents,
      timestamp,
      content,
      programs,
      portraits,
      language,
      setLanguage,
      isMobile,
    }),
    [contents, timestamp, content, programs, portraits, language, isMobile],
  );

  if (!content || !portraits) return null;
  return (
    <HttpsRedirect>
      <AppContext.Provider value={contextValue}>
        <div className="App">
          <MobileNavSlider
            isOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            onStateChange={(state) => setMenuOpen(state.isOpen)}
            id="item-container"
            customBurgerIcon={<span>Menu</span>}
          />
          <script src="https://unpkg.com/styled-components/dist/styled-components.min.js" />
          {children}
        </div>
        <BottomBanner />
      </AppContext.Provider>
    </HttpsRedirect>
  );
}
