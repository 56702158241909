import styled, { css } from 'styled-components';
import { BorderedListItem } from 'components/utilities/components/BorderedListItem';

const ProfileStyles = css`
  display: flex;
  flex-direction: column;
  height: initial;
  padding: 20px 10px;
  box-sizing: border-box;
  margin: 0px;
  margin-right: -1px;
  border: none;
  text-align: left;
  &:hover {
    a {
      background: ${(props) => props.theme.colors.blue2};
      transition: background, 0.2s;
    }
  }
  h5 {
    font-weight: 600;
  }
  h6 {
    /* display: none; */
    margin-bottom: 0px;
    margin-top: 0px;
  }
  span {
    margin-top: 5px;
  }
  p {
    margin-top: 5px;
    font-size: 13px;
  }
  a {
    font-size: 14px;
    font-weight: 600;
    background: ${(props) => props.theme.colors.black6};
    width: fit-content;
    padding: 2px 7px 2px 5px;
    border-radius: 2px;
    color: ${(props) => props.theme.colors.white};
  }
  @media screen and (max-width: 640px) {
    margin: 0px;
    max-width: 50%;
    p {
      max-width: 80% !important;
    }
    h5 {
      max-width: 80%;
    }
    h6 {
      max-width: 80%;
    }
    .image {
      max-width: 80%;
    }
  }
  &:first-child {
    border-radius: 3px;
  }
  .image {
    background-size: cover;
    background-position-x: center;
    margin-top: -20px;
    border-radius: 2px;
    box-shadow: 2px 1px 21px ${(styledProps) => styledProps.theme.colors.white9};
    background-color: ${(styledProps) => styledProps.color};
  }
`;

const StyledProfileCard = styled(BorderedListItem)`
  ${(props) => props.renderCss()}
`;

function ProfileCard(props) {
  const { type, ...otherProps } = props;

  const renderCss = () => {
    switch (type) {
      case 'large':
        return css`
          width: 100%;
          margin: 0;
          padding: 10px;
          padding-top: 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: left;
          box-sizing: border-box;
          border: none;
          max-width: 280px;
          border-radius: 3px;
          &:hover {
            a {
              background: ${(styledProps) => styledProps.theme.colors.blue2};
              transition: background, 0.2s;
            }
          }
          @media screen and (max-width: 640px) {
            max-width: 390px;
          }
          h6 {
            padding: 0px;
            margin-right: 5px;
            margin-top: 20px;
            font-size: 14px;
            font-weight: 600;
            font-family: pt sans;
            text-transform: capitalize !important;
            display: flex;
            height: 20px;
            width: fit-content;
            margin-bottom: 5px;
            color: ${(styledProps) => styledProps.theme.colors.black6};
          }
          &:first-child {
            border-radius: 3px;
          }
          .image {
            height: 220px;
            width: 100% !important;
            margin-bottom: 10px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            background-position: left;
            @media screen and (max-width: 640px) {
              background-position: left;
            }
          }
          a {
            font-size: 14px;
            font-weight: 600;
            background: ${(styledProps) => styledProps.theme.colors.black6};
            width: fit-content;
            padding: 2px 7px 2px 5px;
            border-radius: 2px;
            color: white;
          }
          @media screen and (max-width: 640px) {
            margin: 0 auto;
            margin-bottom: 20px;
          }
        `;
      case 'medium':
        return css`
          ${ProfileStyles}
          max-width: 220px;
          margin-bottom: 20px !important;
          background-color: transparent !important;
          &.moises {
            .image {
              background-position-y: -16px;
              background-size: 166px;
            }
          }
          .meta-data {
            @media screen and (max-width: 640px) {
              margin-bottom: 20px;
            }
          }
          @media screen and (max-width: 640px) {
            padding: 0px 10px;
          }
          .image {
            background-position-y: center;
            height: 150px !important;
            max-width: 150px;
            margin-bottom: 20px;
            background-color: ${(styledProps) =>
              styledProps.theme.colors.purple6};
          }
        `;
      case 'small':
        return css`
          ${ProfileStyles}
          width: 160px;
          margin-bottom: 40px !important;
          overflow: hidden;
          &:nth-child(odd) {
            .image {
              transform: rotate(-0.5deg);
            }
          }
          &:nth-child(even) {
            .image {
              transform: rotate(0.5deg);
            }
          }
          @media screen and (max-width: 640px) {
            padding: -1px 10px;
          }
          .image {
            background-position-y: initial;
            height: 120px !important;
            max-width: 110px;
            margin-bottom: 5px !important;
          }
        `;
      default:
        return css`
          ${ProfileStyles}
          width: 160px;
          margin-bottom: 40px !important;
          overflow: hidden;
          &:nth-child(odd) {
            .image {
              transform: rotate(-0.5deg);
            }
          }
          &:nth-child(even) {
            .image {
              transform: rotate(0.5deg);
            }
          }
          @media screen and (max-width: 640px) {
            padding: -1px 10px;
          }
          .image {
            background-position-y: initial;
            height: 120px !important;
            max-width: 110px;
            margin-bottom: 5px !important;
          }
        `;
    }
  };

  return <StyledProfileCard renderCss={renderCss} {...otherProps} />;
}

export default ProfileCard;
