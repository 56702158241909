import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from 'AppContext';
import styled from 'styled-components';
import { Nav } from 'components/base/nav';
import Signups from 'components/pages/about/signup';
import Header from 'components/pages/homepage/homepageheader';
import HorizontalTimeline from 'components/utilities/components/HorizontalTimeline';
import { theme } from 'components/utilities/utils';
import Footer from 'components/views/FooterView';
import ProgramSection from 'components/views/ProgramSection';
import Subheader from 'components/views/Subheader';
import FaqComponent from 'components/views/faq';
import WorkshopsView from 'components/views/workshopsview';

const Section = styled.section`
  text-align: center;
  padding: 0px;
  max-width: 1280px;
`;

export default function Media() {
  const { content } = useContext(AppContext);
  return (
    <Section>
      <Helmet>
        <title>Parkdale Centre for Innovation</title>
        <meta name="description" content="Media, Arts, News & kids" />
        <meta property="og:title" content="Parkdale Centre" />
        <meta property="og:image" content="images/previews/incubators.png" />
        <meta
          property="og:url"
          content="https://www.parkdaleinnovates.org/programs"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Media Lab, kids program, Podcast & video workshops, Parkdale Radio"
        />
        <meta
          property="og:description"
          content="Grow your idea, get traction, and scale your work at Parkdale Centre. You bring the motivation."
        />
        <meta property="og:image" content="images/previews/incubators.png" />
      </Helmet>
      <Header
        pageName="kids"
        color={
          content.kids.Headers['Header text background color'] ||
          theme.colors.white27
        }
        nav={<Nav />}
        showNavigation
        showLanguageSelector
      />
      <Subheader
        pageName="kids"
        section="SubHeaders"
        lcolor2="red"
        rcolor1={theme.colors.white}
        borderleft={theme.colors.red5}
        borderright="transparent"
        background={theme.colors.white5}
      />
      <HorizontalTimeline
        pageName="kids"
        listName="Timeline"
        titleName="Hero"
      />
      <WorkshopsView />
      <ProgramSection
        color={content.kids.Footer.ThemeColor}
        programName={[
          'kidsInnovate1',
          'kidsInnovate2',
          'kidsInnovate',
          'kidsInnovate3',
          'kidsInnovate4',
        ]}
      />
      <FaqComponent pageName="kids" />
      <Signups pageName="kids" />
      <Footer pageName="kids" />
    </Section>
  );
}
