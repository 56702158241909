import { useContext, useMemo } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import DomainVerificationRoundedIcon from '@mui/icons-material/DomainVerificationRounded';
import DoorbellTwoToneIcon from '@mui/icons-material/DoorbellTwoTone';
import FolderCopyTwoToneIcon from '@mui/icons-material/FolderCopyTwoTone';
import Groups2TwoToneIcon from '@mui/icons-material/Groups2TwoTone';
import AppContext from 'AppContext';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { NavigationItem } from './components/NavigationItem';

const NavContainer = styled.div`
  ${(props) =>
    props.isMobileNav &&
    css`
      nav {
        border-radius: 0px 3px 3px 0px;
      }
      li,
      a {
        width: 100% !important;
        padding: 0px !important;
        margin: 0px;
        text-align: left;
        font-size: 12px;
        span {
          padding-left: 20px;
          font-weight: 500;
        }
      }
      li:first-of-type {
        a > span {
          padding-top: 15px !important;
        }
      }
      li:last-of-type {
        a > span {
          padding-bottom: 15px !important;
        }
      }
      span {
        text-align: left;
      }
    `}
`;

const NavList = withReveal(
  styled.ul`
    ${(props) =>
      props.isMobileNav &&
      css`
        max-height: calc(98vh - 54px);
        height: 100%;
        overflow-y: auto;
        scrollbar-width: thin;
        overflow-x: hidden;
      `}
    background-size: cover;
    background-position-y: 40px;
    background-repeat: no-repeat;
    margin: 0px 0px;
    padding-left: 0px;
    font-family: pt sans;
    font-weight: 300;
    letter-spacing: 1px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: ${(props) => (props.isMobileNav ? 'wrap' : 'nowrap')};
    ${up('xl')} {
      border: none;
    }
  `,
  <Fade distance="10%" fraction={1} />,
);

const ContactDetails = styled.div`
  background-color: #06060b;
`;

const Contact = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.white6};
  display: inline-block;
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;
  line-height: 21px;
  padding: 15px 10px 15px 15px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.black2};
  width: 265px;
  outline: none;
  a {
    color: ${(props) => props.theme.colors.white};
    &:hover {
      color: ${(props) => props.theme.colors.orange};
    }
    &:last-of-type {
      font-size: 13px;
      color: ${(props) => props.theme.colors.white6};
      font-weight: 900;
      margin-bottom: 10px;
      display: block;
    }
  }
`;

const Title = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.white24};
  width: 100%;
  height: 60px;
  padding: 15px 10px 10px 80px;
  box-sizing: border-box;
  outline: none;
  font-size: 21px;
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  border: none;
  background: #f7f7f7;
  padding: 32px 0px;

  ${(props) =>
    props.showRing &&
    css`
      &:after {
        content: '';
        background-image: url('/images/logo-full.png');
        background-size: contain;
        background-repeat: no-repeat;
        float: left;
        height: 80px;
        width: 60px;
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
      }
    `}
`;

/**
 * Get object with navigation structure
 * @param content
 * @returns
 */
const getAFNavigationConfig = () => [
  {
    title: [<DoorbellTwoToneIcon />],
    to: '/af/alphaflight',
    hideDesktopNav: true,
  },
  {
    title: <DateRangeTwoToneIcon />,
    to: '/af/events',
    children: [],
  },
  {
    title: <DomainVerificationRoundedIcon />,
    to: '/af/progress',
    children: [],
  },
  {
    title: <FolderCopyTwoToneIcon />,
    to: '/af/Documents',
    children: [],
  },
  {
    title: <Groups2TwoToneIcon />,
    to: '/af/people',
    children: [],
  },
];

const getNavigationConfig = (content) => {
  const navigationConfig = [];

  Object.keys(content).forEach((pageKey) => {
    const page = content[pageKey];
    const navItem = {
      title: page.NAV.PageName || pageKey,
      to: page.NAV.Url ? page.NAV.Url : null, // Set to null if no URL prop is passed
      children: [],
    };

    const parentNavItem = navigationConfig.find(
      (item) =>
        item.title.toLowerCase() === (page.NAV.Parent || '').toLowerCase(),
    );

    if (parentNavItem) {
      parentNavItem.children.push(navItem);
    } else {
      navigationConfig.push(navItem);
    }
  });

  return navigationConfig;
};

export default function Navigation(props) {
  const { platformApp } = useContext(AppContext);
  const { selectedIndex } = props;

  const { content } = useContext(AppContext);
  const { onItemHover, index, isMobileNav } = props;
  const navItems = useMemo(
    () =>
      platformApp
        ? getAFNavigationConfig(content)
        : getNavigationConfig(content),
    [platformApp, content],
  );

  if (process.env.REACT_APP_TYPE === 'platform') {
    return '';
  }

  return (
    <NavContainer isMobileNav={isMobileNav}>
      {isMobileNav && <Title showRing />}
      <NavList isMobileNav={isMobileNav}>
        {navItems.map((item, i) => (
          <NavigationItem
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            to={item.to}
            childItems={item.children}
            onItemHover={onItemHover}
            index={i}
            showBorder={index === i}
            isMobileNav={isMobileNav}
            disabled={item.disabled}
            hideDesktopNav={item.hideDesktopNav}
            isSelected={index === selectedIndex}
          >
            {item.title}
          </NavigationItem>
        ))}
        {isMobileNav && (
          <div>
            {/* <LoginContainer /> */}
            <ContactDetails>
              <Contact>
                <a href={content.homepage.NAV.LinkedInLink}>
                  LinkedIn: {content.homepage.NAV.LinkedInText}
                </a>
                <br />
                <a href={`mailto:${content.homepage.NAV.EmailLink}`}>
                  {content.homepage.NAV.EmailText}
                </a>
                <br />
                <a href={content.homepage.NAV.AddressLink}>
                  {content.homepage.NAV.AddressText}
                </a>
                <br />
                <br />
                <a
                  className="disclaimer"
                  href={content.homepage.NAV.DisclaimerLink}
                >
                  {content.homepage.NAV.DisclaimerText}
                </a>
              </Contact>
            </ContactDetails>
          </div>
        )}
      </NavList>
    </NavContainer>
  );
}
