import { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import AppContext from 'AppContext';
import moment from 'moment';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button } from 'components/utilities/components/Button';
import { theme } from 'components/utilities/utils';

const Program = styled.div`
  background: ${(props) => props.background};
  color: ${(props) => props.theme.colors.black};
  border-radius: 0px;
  padding: 40px 35px;
  border-radius: 5px;
  // min-width: 620px;
  height: fit-content;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '620px')};
  float: left;
  text-align: left;
  max-height: 100vh;
  overflow-y: scroll;
  &:hover {
    background: white;
  }
  &.inModal {
    margin-top: 0px !important;
  }
  .buttons {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-left: 8px;
    ${down('sm')} {
      margin-top: -15px;
      margin-left: -23px;
    }
    button {
      width: fit-content;
      background: ${(props) => props.theme.colors.white};
      margin-left: 5px;
      &.faq-button {
        &:hover {
          background: ${(props) => props.theme.colors.black};
          color: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
  ${down('lg')} {
    max-width: initial;
    min-width: initial;
    padding-right: 0px;
    width: -moz-available;
    width: -webkit-fill-available;
  }
  ${down('lg')} {
    padding-top: 40px;
    margin-left: 0px !important;
  }
  ${down('sm')} {
    padding: 30px 0px 20px 45px;
    margin-top: 20px;
    &.inModal {
      box-sizing: border-box;
      margin-left: 0px;
      padding-left: 30px;
    }
  }
  button {
    ${down('sm')} {
      margin-left: -5px;
    }
  }
  .secondary {
    letter-spacing: 1px;
    font-weight: 400;
    justify-content: left;
    text-align: left;
    box-sizing: border-box;
    ${down('sm')} {
      width: 100%;
    }
  }
  &.inModal {
    height: initial;
    margin-left: 15px;
  }
`;

const ProgramTitle = styled.h3`
  color: ${(props) => props.color} !important;
  font-weight: 600;
  font-family: pt sans;
  z-index: 5;
  margin-top: 5px;
  position: relative;
  letter-spacing: 1px;
  margin-bottom: 0px;
  line-height: 40px;
  display: inline;
  text-align: left;
  line-height: 36px;
  font-size: 28px;
  // margin-left: 25px;

  ${down('md')} {
    &:before {
      content: '';
      background-image: url('/images/rings.png');
      background-size: contain;
      background-repeat: no-repeat;
      float: left;
      height: 50px;
      width: 60px;
      display: block;
      position: absolute;
      top: -10px;
      left: -20px;
    }
  }
`;

const ContentContainer = withReveal(
  styled.div`
    margin-top: ${(props) => (props.image ? '20px' : '0px')};
    overflow-x: hidden;
    p {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 15px;
      margin-top: 0px;
    }
    a {
      text-decoration: underline;
      color: ${(props) => props.theme.colors.aqua2};
    }
    img {
      margin: 15px 0px;
      height: initial;
    }
    h2 {
      text-transform: capitalize;
      font-weight: 600;
    }
  `,
  <Fade distance="2%" fraction={0} />,
);

const ProgramContent = styled.div`
  padding-left: 0px;
  margin-bottom: 30px;
  // margin-left: 30px;
  max-width: 97%;
  z-index: 2;
  position: relative;
  img {
    max-width: 100%;
    height: initial;
  }
  figcaption {
    font-size: 14px;
    margin-top: -15px;
    color: #8a8a8a;
    margin-bottom: 50px;
  }
  &.inModal {
    max-width: 100%;
  }
  @media screen and (max-width: 1100px) {
    // margin-left: -20px;
  }
  ol,
  ul {
    margin-top: 60px;
  }
  li {
    font-size: 14px;
    text-indent: -18px;
    font-size: 14px;
    list-style: outside none;
    text-indent: -18px;
    line-height: 21px;
    padding: 20px;
    padding-left: 40px;
    margin: -40px;
    margin-bottom: 43px !important;
    width: 98%;
    background: ${(props) => props.theme.colors.white5};
    position: relative;
    &:hover {
      background-color: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.white};
      ::before {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
  li::before {
    font-size: 1.5em;
    padding-right: 10px;
    position: relative;
    display: inline;
    top: 3px;
    content: '•';
  }
`;

const Avatar = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 500px;
  background-color: transparent;
  background-size: cover;
  background-image: url(${(props) => props.avatar});
  margin-top: 0px;
  margin-left: -3px;
`;

const Share = styled(Button)`
  &:hover {
    background: ${(props) => props.theme.colors.orange2} !important;
  }
`;

const Meta = styled.div`
  margin-top: 4px;
  margin-bottom: 0px;
  font-style: italic;
  padding-bottom: 30px;
`;

const Date = styled.h3`
  flex-direction: row;
  font-size: 16px;
  display: block;
  margin: 0;
  text-transform: capitalize;
`;

const Author = styled.h3`
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 1px;
  display: inline;
  margin: 0 4px 0 0;
  vertical-align: middle;
`;

export default function PostCardContent(props) {
  const {
    post,
    expanded,
    small,
    hideButtons,
    showContent,
    expandPost,
    single,
    sharePost,
    copySuccess,
    maxWidth,
  } = props;

  const { language } = useContext(AppContext);
  console.log('post', post);
  return (
    <Program
      color1={post.color1}
      color2={post.color2}
      maxWidth={maxWidth}
      className={small ? 'inModal' : ''}
    >
      <ProgramTitle color={post.color1}>{post.title}</ProgramTitle>
      <ProgramContent
        color={post.color1}
        image={post.image}
        className={small && 'inModal'}
      >
        <Avatar {...props} avatar={post.avatar} />
        <Meta>
          <Author>Posted by {post.author}</Author>
          <Date title={moment(post.date).format('MMMM D, YYYY')}>
            {moment(post.date).format('MMMM D, YYYY')}
          </Date>
        </Meta>
        <img alt="" src={post.image} />
        <ContentContainer
          image={post.image}
          className="content-container"
          expanded={expanded}
          dangerouslySetInnerHTML={{
            __html: !expanded && !showContent ? post.excerpt : post.content,
          }}
        />
      </ProgramContent>
      {!hideButtons && (
        <div className="buttons">
          {!single && (
            <Button
              textcolor={theme.colors.black}
              className="outline"
              text={expanded ? 'Collapse Post' : 'Expand Post'}
              onClick={expandPost}
            />
          )}
          <Share
            textcolor={copySuccess ? 'green' : theme.colors.black}
            className="outline"
            text={copySuccess ? 'Link Copied!' : 'Share'}
            onClick={(e) => sharePost(e, post.id)}
            link={`/${language}/blog/${post.id}`}
          />
        </div>
      )}
    </Program>
  );
}
