import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppContext from 'AppContext';
import { up } from 'styled-breakpoints';
import styled, { css, keyframes } from 'styled-components';
import Link from 'components/base/Link';

const ItemAnimation = keyframes`
  0% {
    opacity: 0;
    max-height: 40px;
    max-width: 100%;
    opacity: 0;
  }
  100% {
    max-height: 40px;
    max-width: 100%;
    opacity: 1;
  }`;

const StyledTag = styled.span`
  border-radius: 0px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.color};
  padding: 13px 21px;
  transition: padding, 0.2s;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-family: 'pt sans';
  font-weight: 600 !important;
  text-transform: none;
  box-sizing: border-box;
  width: 100%;
  box-shadow: none !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  @media screen and (max-width: 820px) {
    font-size: 14px;
    padding: 15px;
    transition: padding, 0.2s;
  }

  ${(props) =>
    props.isActive &&
    css`
      background: ${props.theme.colors.black2};
      color: ${props.theme.colors.white};
    `}

  ${(props) =>
    props.platformApp &&
    !props.isMobileNav &&
    css`
      ${up('lg')} {
        padding: 12.5px 30px;
      }
    `}
`;

const ChildContainer = styled.ul`
  margin: 0px 0px !important;
  padding-left: 0px;
  position: absolute;
  top: 100%;
  list-style-type: none;
  width: 100%;
`;

const SubListItem = styled.li`
  list-style: none;
  width: fit-content;
  & > a {
    animation: ${ItemAnimation} 0.2s;
    width: max-content;
    display: flex;
    align-items: center;
    min-width: 56px;
    font-weight: 700;
    letter-spacing: 1px;
    color: ${(props) => props.theme.colors.white};
    padding: 13px 21px;
    margin-left: 0px;
    ${(props) =>
      props.isMobileNav &&
      css`
        color: ${props.theme.colors.black};
      `}
    ${(props) =>
      !props.isMobileNav &&
      css`
        background: ${props.theme.colors.black2};
      `}
    &:hover {
      background: ${(props) => props.theme.colors.yellow};
      ${(props) =>
        props.isMobileNav &&
        css`
          color: ${props.theme.colors.white};
          box-shadow: none !important;
          font-weight: 600;
          transition: background 0.3s;
        `}
    }
    span {
      width: 100%;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      font-size: 14px;
      ${(props) =>
        props.isMobileNav &&
        css`
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 35px !important;
        `}
      ${(props) =>
        props.active &&
        css`
          border-color: ${props.theme.colors.black2};

          background: ${props.theme.colors.black2};
          color: ${props.theme.colors.white};
          font-weight: 600;
          transition: background 0.3s;
        `}
    }
  }
`;

const ListItem = styled.li`
  box-sizing: border-box;
  border-radius: 1px;
  list-style: none;
  position: relative;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px;
  // transition: height, 1s;

  @media (max-width: 960px) {
    a {
      height: 60px; /* Set a fixed height for <a> on screens below 960px */
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;

    span {
      letter-spacing: 1px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: left;
      height: 100%;
      letter-spacing: 1px;
      box-sizing: border-box;
    }
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.black2};
    background-color: ${(props) => props.theme.colors.white};
    transition: all 0.3s;
    ${StyledTag} {
      background: ${(props) => props.theme.colors.black2};
      color: ${(props) => props.theme.colors.white};
      font-weight: 600;
      transition: background 0.3s;
    }
  }
  ${(props) =>
    props.active &&
    css`
      border-color: ${props.theme.colors.black2};

      ${StyledTag} {
        background: ${props.theme.colors.black2};
        color: ${props.theme.colors.white};
        font-weight: 600;
        transition: background 0.3s;
      }
    `}

  ${ChildContainer} {
    display: none;
  }

  &:hover {
    z-index: ${(props) => (props.isMobileNav ? 0 : 10)};

    ${ChildContainer} {
      display: block;
    }
  }

  ${(props) =>
    props.isMobileNav &&
    css`
     @media screen {
      display: flex;
      flex-direction: column;
      height: auto;

    ${ChildContainer} {
      display: block;
      position: relative;
      top: 0;
     }
  `}

  ${(props) =>
    !props.isClickable &&
    css`
      cursor: default;
      & > a {
        pointer-events: none;
      }
    `}
`;

export function NavigationItem(props) {
  const { platformApp, language } = useContext(AppContext);

  const {
    children,
    to,
    childItems = [],
    onItemHover,
    index,
    showBorder,
    isMobileNav,
    hideDesktopNav,
    disabled,
  } = props;

  const [activeLink, setActiveLink] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Split the pathname to get the individual parts
    const parts = location.pathname.split('/');
    const parentName = parts.length > 2 ? parts[2] : '';

    // Construct the full URL for each child and find the active child
    const activeChild = childItems.find(
      (child) => `/${parts[1]}/${parentName}${child?.to}` === location.pathname,
    );

    // If isMobileNav is true, we want to maintain the parent name as the active link
    if (isMobileNav) {
      if (location.pathname === `/${language}${to}`) {
        setActiveLink(props);
      } else {
        setActiveLink(null);
      }
    }
    // If isMobileNav is false, we want to maintain the child name as the active link
    else if (activeChild) {
      setActiveLink(activeChild);
    } else if (location.pathname === `/${language}${to}`) {
      setActiveLink(props);
    } else {
      setActiveLink(null);
    }
  }, [location, childItems, props, to, language, isMobileNav]);

  return (
    <ListItem
      hideDesktopNav={hideDesktopNav}
      active={
        location.pathname === `/${language}${to}` ||
        location.pathname === `/${language}${activeLink?.to}`
      }
      onMouseEnter={() => {
        if (onItemHover) {
          onItemHover(index);
        }
        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
      showBorder={showBorder}
      isMobileNav={isMobileNav}
      isClickable={!disabled}
    >
      <Link to={to}>
        <StyledTag
          isMobileNav={isMobileNav}
          isActive={activeLink}
          platformApp={platformApp}
        >
          {(!isHovered && activeLink?.title) || children}
        </StyledTag>
      </Link>
      {childItems.length > 0 && (
        <ChildContainer>
          {childItems.map((item, i) => (
            <SubListItem
              key={i}
              isMobileNav={isMobileNav}
              active={location.pathname === `/${language}${to}${item.to}`}
            >
              <Link to={to + item.to || '/'}>
                <span>{item.title}</span>
              </Link>
            </SubListItem>
          ))}
        </ChildContainer>
      )}
    </ListItem>
  );
}
