import { useContext } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import AppContext from 'AppContext';
import { down, up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { isIntNumber } from '../utils';

const CardContainer = styled.div`
  //   ${(props) => props.theme.colors.white32} 0px 3px 4px 0px,
  //   ${(props) => props.theme.colors.white32} 0px 1px 8px 0px;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 13px -4px ${(props) => props.theme.colors.white12};
  border-radius: 4px;
  text-align: left;
  padding: 15px 16px 17px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.white};
  margin: 0x 20px 0px 0px;
  ${up('md')} {
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    margin-bottom: 15px;
  }
`;

const CardContainerPlatform = styled(CardContainer)`
  width: 333px;
  box-sizing: border-box;
  border: solid 1px transparent;
  box-shadow: 0px 0px 13px -4px ${(props) => props.theme.colors.white30};
  margin-bottom: 0px;
  ${up('md')} {
    margin-bottom: 0px;
  }
  ${(props) =>
    props.selected &&
    css`
      border-radius: 4px;
      border: 1px solid ${props.theme.colors.black};
      ${props.toolItem?.width === 'mobile' &&
      css`
        ${up('md')} {
          width: 326px;
          margin-bottom: 0px;
        }
      `}
    `}
  ${down('sm')} {
    width: 195px;
    padding: 10px 16px 18px 16px;
  }
`;

const Event = styled.p`
  font-weight: ${(props) => (props.isMetric ? 700 : 600)};
  font-size: ${(props) => (props.isMetric ? '42px' : '17px')};
  line-height: 27px;
  letter-spacing: 0px;
  font-family: pt sans;
  color: ${(props) => props.color ?? props.theme.colors.black9};
  margin: ${(props) => (props.isMetric ? '10px 0px' : '0')};
`;

const EventPlatform = styled(Event)`
  font-size: 14px;
  font-weight: 700;
  ${down('sm')} {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const Result = styled.span`
  background: ${(props) => props.color ?? props.theme.colors.black5};
  border-radius: 3px;
  padding: 3px 6px;
  font-family: 'Roboto';
  max-width: max-content;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15008px;
  margin-top: 2px;
  color: ${(props) => props.theme.colors.white};
`;

const ResultPlatform = styled(Result)`
  font-size: 11px;
  ${(props) =>
    props.selected &&
    css`
      border-radius: 4px;
      background: ${props.theme.colors.white6};
      color: ${props.theme.colors.black};
    `}
`;

const Description = styled.p`
  margin: 7px 0 10px 0;
  font-family: ${(props) => (props.isMetric ? 'sans-serif' : 'Roboto')};
  font-weight: ${(props) => (props.isMetric ? 700 : 400)};
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.13132px;
  color: ${(props) => props.theme.colors.black9};
`;

const DescriptionPlatform = styled(Description)`
  font-size: 13px;
  line-height: 21px;
  ${down('sm')} {
    display: none;
  }
`;

const Duration = styled.div`
  padding: 3px 12px;
  background: ${(props) => props.theme.colors.white5};
  border-radius: 1px;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15008px;
  color: ${(props) => props.theme.colors.black5};
  width: initial;
  margin-left: -5px;
`;

export default function TimelineCard(props) {
  const { platformApp } = useContext(AppContext);

  const { toolItem, item, count, isMetric, selected } = props;
  const startCount = parseInt((count * 30) / 100, 10);
  return (
    <>
      {platformApp && (
        <CardContainerPlatform
          selected={selected}
          fullWidth={props.fullWidth}
          toolItem={toolItem}
        >
          <EventPlatform>
            {count ? `${count}. ` : null}
            {item.event}
          </EventPlatform>
          {item.tag && (
            <ResultPlatform selected={selected} color={item.color}>
              {item.tag}
            </ResultPlatform>
          )}
          <div>
            <DescriptionPlatform isMetric={isMetric}>
              {item.description}
            </DescriptionPlatform>
            {item.duration && <Duration>{item.duration}</Duration>}
          </div>
        </CardContainerPlatform>
      )}
      {!platformApp && (
        <CardContainer selected={selected} fullWidth={props.fullWidth}>
          {isMetric ? (
            <Event color={item.color} isMetric={isMetric}>
              <CountUp
                redraw
                decimals={isIntNumber(count) ? 0 : 1}
                start={startCount}
                end={count}
                duration={2}
                prefix={item.prefix ?? ''}
                suffix={item.affix ?? ''}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </Event>
          ) : (
            <Event>
              {count ? `${count}. ` : null}
              {item.event}
            </Event>
          )}

          {item.tag && (
            <Result selected={selected} color={item.color}>
              {item.tag}
            </Result>
          )}

          <div>
            <Description isMetric={isMetric}>{item.description}</Description>
            {item.duration && <Duration>{item.duration}</Duration>}
          </div>
        </CardContainer>
      )}
    </>
  );
}
