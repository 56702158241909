import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import Navigation from 'components/base/navigation/navigation';

const DesktopNav = styled.nav`
  margin: 0 auto;
  border-radius: 2px;
  z-index: 3;
  // background-color: ${(props) => props.theme.colors.white};
  &:hover {
    cursor: pointer;
  }

  ${down('md')} {
    display: none;
  }
`;

const NavContainer = styled.div`
  z-index: 3;
`;

export function Nav(props) {
  const { ...rest } = props;

  return (
    <NavContainer {...rest}>
      <DesktopNav>
        <Navigation isMobileNav={false} />
      </DesktopNav>
    </NavContainer>
  );
}
