import { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button } from 'components/utilities/components/Button';
import Content from 'components/utilities/components/Content';
import LanguageSelector from 'components/utilities/components/LanguageSelector';

const LeftRightContainer = styled.div`
  max-width: 1280px;
  margin: 40px auto 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  border-radius: 0px;
  background: ${(props) => props.background || props.theme.colors.white5};
  padding: 60px 20px;
  box-sizing: border-box;
  z-index: 0;
  ${down('md')} {
    padding: 0px;
    margin: 10px;
  }
  ${down('sm')} {
    background-color: transparent;
  }
  .left,
  .right {
    color: ${(props) => props.theme.colors.black};
    background-repeat: no-repeat;
    background-blend-mode: hard-light;
    background-size: cover;
    background-position: right;
    box-sizing: border-box;
    padding: 140px 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 460px;
    border-radius: 2px;
    @media screen and (max-width: 940px) {
      margin-bottom: 0px;
      max-width: 400px;
      background-position-y: center;
    }
    ${down('sm')} {
      width: 100%;
      max-width: initial;
      padding: 40px 40px 60px 40px;
    }
    .tag {
      font-size: 16px;
      padding: 5px 18px 10px 18px;
      font-weight: 600;
      margin-top: 5px;
      margin-left: -3px;
      color: ${(props) => props.theme.colors.white} !important;
    }
    h3 {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 600;
    }
    h5 {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      font-family: 'Roboto';
      margin-top: 0px;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
    }
  }
  .left {
    margin-bottom: 0px;
    border-radius: 2px 0px 0px 5px;
    background-image: url(${(props) => props.lbackground});
    text-align: right;
    background-color: ${(props) => props.lcolor1};
    max-width: 100%;
    @media screen and (max-width: 790px) {
      margin: 0px;
    }
    ${down('sm')} {
      height: 300px;
      background-color: transparent;
    }
    .tag {
      background-color: ${(props) => props.lcolor2};
    }
  }
  .right {
    background-color: ${(props) => props.rcolor1};
    border-radius: 0px 5px 5px 0px;
    text-align: left;
    height: fit-content;
    @media screen and (max-width: 1024px) {
      padding-left: 22px;
    }
    .tag {
      background-color: ${(props) => props.rcolor2};
    }
  }
`;

const PartnerMeta = withReveal(
  styled.p`
    font-size: 18px;
    line-height: 24px;
  `,
  <Fade distance="5px" fraction={1} />,
);

const PartnerImages = styled.div`
  display: flex;
  flex-direction: row;
  .image {
    height: 50px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 121px;
    background-position-y: center;
    background-position-x: left;
    margin-right: 15px;
  }
`;

const Partner1 = styled.div`
  background: url(${(props) => props.bg1});
`;

const Partner2 = styled.div`
  background: url(${(props) => props.bg2});
`;

export default function Subheader(props) {
  const { content } = useContext(AppContext);
  const { pageName, section, showLanguageSelector } = props;

  const subheaderObject = content[pageName][section];

  return (
    <Content content={subheaderObject}>
      <LeftRightContainer
        id="Subheader"
        {...props}
        lbackground={
          subheaderObject.Image?.includes('http')
            ? subheaderObject.Image
            : `/images${subheaderObject.Image}`
        }
        rcolor2={subheaderObject.ButtonColor}
      >
        <div className="left" />
        <div className="right">
          <h3>{subheaderObject.Heading}</h3>
          <h5>{subheaderObject.Subheading}</h5>
          <PartnerImages>
            <Partner1
              className="image"
              alt="partner"
              bg1={
                subheaderObject.PartnerImage1?.includes('http')
                  ? subheaderObject.PartnerImage1
                  : `/images${subheaderObject.PartnerImage1}`
              }
            />
            <Partner2
              className="image image2"
              alt="partner"
              bg2={
                subheaderObject.PartnerImage2?.includes('http')
                  ? subheaderObject.PartnerImage2
                  : `/images${subheaderObject.PartnerImage2}`
              }
            />
          </PartnerImages>

          <PartnerMeta>{subheaderObject.Paragraph}</PartnerMeta>
          <Button
            text={subheaderObject.ButtonText}
            background={subheaderObject.ButtonColor}
            link={subheaderObject.ButtonTarget}
          />
        </div>
        {showLanguageSelector && (
          <LanguageSelector style={{ top: '30px', mobileTop: '20px' }} />
        )}
      </LeftRightContainer>
    </Content>
  );
}
