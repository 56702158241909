import { useContext, useMemo } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import { useNavigate } from 'react-router-dom';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Container } from 'components/base/view';
import { Button } from 'components/utilities/components/Button';
import Content from 'components/utilities/components/Content';
import { ListItem } from 'components/utilities/components/ListItem';
import { SectionHeading } from 'components/utilities/components/SectionHeading';
import { Tag } from 'components/utilities/components/SingleTag';
import { theme } from 'components/utilities/utils';

const Section = styled.section`
  padding: 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  justify-content: center;
  max-width: none;
  max-width: 1280px;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.white5};
  padding-bottom: 40px;
  margin: 80px auto 30px;
`;

const PartnerSectionHeading = styled(SectionHeading)`
  margin-right: 60px;
  float: right;
  margin-top: 40px;
  box-shadow: none;
  font-size: 24px;
  text-transform: none;
  font-family: Raleway;
  font-weight: 600;
  :after {
    padding: 0px;
  }
`;

const PartnerContainer = styled(Container)`
  margin: 0px auto 0px;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  background-size: cover;
  background: url('/images/backgrounds/background34.png');
  background-repeat: no-repeat;
  background-position-y: 20%;
  background-position-x: 14%;
  background-color: ${(props) => props.theme.colors.white};
  background-size: contain;
  ${down('md')} {
    margin-top: 0px;
    padding-top: 0px;
  }
  b {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
`;

const PartnerList = styled.ul`
  margin: 0;
  display: flex;
  align-items: center;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  padding: 100px 45px 50px 80px;
  position: relative;
  flex-direction: row-reverse;
  gap: 25px;
  &:nth-child(odd) {
    background: ${(props) => props.theme.colors.yellow7};
  }
  &:nth-child(even) {
    background: ${(props) => props.theme.colors.yellow8};
  }
  &:last-child {
    background: ${(props) => props.theme.colors.white5};
    padding-top: 30px;
  }
  &:nth-child(6) {
    padding-top: 0px;
    padding-bottom: 5px;
    ${down('sm')} {
      img {
        width: 100%;
      }
    }
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  img {
    // max-height: 60px;
  }
  ${down('sm')} {
    padding: 100px 20px 20px;
    gap: 0px 25px;
  }
`;

const Partner = withReveal(
  styled(ListItem)`
    padding: 0px
    margin: 0;
    margin-left: 5px;
    text-align: center;
    display: flex;
    .itemcontent {
      display: none;
    }
    ${down('sm')} {
      height: initial;
      width: fit-content;
    }
    img {
      // margin-top: -10px;
      max-height: ${(props) => props.maxHeight};
    }
    &.button {
      margin-top: 0px;
      font-weight: 800;
      .itemcontent {
        display: flex;
        max-width: none;
      }
      img {
        display: none;
      }
    }
  `,
  <Fade cascade right distance="10%" fraction={1} />,
);

const PartnerCategory = styled(Tag)`
  position: absolute;
  top: 40px;
  right: 48px !important;
  color: ${(props) => props.theme.colors.black20} !important;
  text-transform: uppercase;
  background: transparent !important;
  font-size: 12px;
  font-weight: 500;
  font-weight: 600;
  letter-spacing: 1px;
  ${down('lg')} {
    right: 20px !important;
  }
`;

export default function Partners(props) {
  const { language, content } = useContext(AppContext);
  const navigate = useNavigate();
  const { pageName } = props;
  const pageObject = content[pageName].PartnerLogos;
  const partnerLogoObj = pageObject;

  const partnerLogos = useMemo(() => {
    if (!partnerLogoObj) return [];

    const result = {
      organizational: [],
      programs: [],
      ecosystem: [],
      media: [],
      vip: [],
    };
    const itemNumber = parseInt(Object.keys(partnerLogoObj).length / 3, 10);

    for (let index = 1; index <= itemNumber; index++) {
      if (partnerLogoObj[`category${index}`]) {
        result[partnerLogoObj[`category${index}`]]?.push({
          image: partnerLogoObj[`image${index}`],
          text: partnerLogoObj[`text${index}`],
          maxHeight: partnerLogoObj[`style${index}`],
        });
      }
    }

    return result;
  }, [partnerLogoObj]);

  return (
    <Content content={partnerLogoObj}>
      <Section>
        <PartnerContainer>
          <PartnerSectionHeading
            title="Ecosystem Support"
            image="/images/icons/icon-partners.png"
          />
          <PartnerList>
            <PartnerCategory>Organizational</PartnerCategory>
            {partnerLogos?.organizational.map((partnerLogo, idx) => (
              <Partner
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                image={partnerLogo.image}
                title={partnerLogo.text}
                maxHeight={partnerLogo.maxHeight || 'initial'}
              />
            ))}
          </PartnerList>
          {/* <PartnerList>
            <PartnerCategory>Program Delivery</PartnerCategory>
            {partnerLogos?.programs.map((partnerLogo, idx) => (
              <Partner
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                image={partnerLogo.image}
                title={partnerLogo.text}
                maxHeight={partnerLogo.maxHeight || 'initial'}
              />
            ))}
          </PartnerList> */}
          <PartnerList>
            <PartnerCategory>In the Media</PartnerCategory>
            {partnerLogos?.media.map((partnerLogo, idx) => (
              <Partner
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                image={partnerLogo.image}
                title={partnerLogo.text}
                maxHeight={partnerLogo.maxHeight || 'initial'}
              />
            ))}
          </PartnerList>
          {/* <PartnerList>
            <PartnerCategory>Ecosystem</PartnerCategory>
            {partnerLogos?.ecosystem.map((partnerLogo, idx) => (
              <Partner
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                image={partnerLogo.image}
                title={partnerLogo.text}
                maxHeight={partnerLogo.maxHeight || 'initial'}
              />
            ))}
          </PartnerList> */}
          {/* <PartnerList>
            {partnerLogos?.vip?.map((partnerLogo, idx) => (
              <Partner
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                image={partnerLogo.image}
                title={partnerLogo.text}
                width={partnerLogo.style || 'initial'}
              />
            ))}
          </PartnerList> */}
          <PartnerList>
            <Button
              fontSize="17px"
              letterSpacing="1px"
              className="outline"
              textcolor={theme.colors.black}
              background={theme.colors.white}
              text={pageObject.buttontext}
              onClick={() => {
                if (
                  pageObject.buttonurl &&
                  pageObject.buttonurl.startsWith('http')
                ) {
                  // If it's an external URL (starts with "http"), navigate to it directly
                  window.location.href = pageObject.buttonurl;
                } else {
                  const internalLink = `/${language}${pageObject.buttonurl}`;
                  console.log('Internal Link Text:', internalLink); // Log the link text
                  navigate(internalLink);
                }
              }}
              isExternalLink
            />
          </PartnerList>
        </PartnerContainer>
      </Section>
    </Content>
  );
}
