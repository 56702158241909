import { useContext, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppContext from 'AppContext';
import { getLocaleLink } from 'components/utilities/utils';

export default function Link({ children, to }) {
  const { language } = useContext(AppContext);

  const link = useMemo(() => getLocaleLink(language, to), [language, to]);
  return <RouterLink to={link}>{children}</RouterLink>;
}
