import { useContext } from 'react';
import AppContext from 'AppContext';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Nav } from 'components/base/nav';
import { Container } from 'components/base/view';

const HeaderContainer = styled.header`
  position: relative;
  padding: 0px;
  background-color: ${(props) => props.theme.colors.white};
  height: 700px;
  display: flex;
  justify-content: flex-start;
  max-width: 1400px;
  width: 100%;
}
  ${down('md')} {
    padding-bottom: 40px;
    height: fit-content;
  }
  ${down('sm')} {
    height: fit-content;
  }
`;

const HeaderContainerPlatform = styled(HeaderContainer)`
  height: 77px;
  width: 100%;
  max-width: 100%;
  ${down('md')} {
    padding-bottom: 0px !important;   
    overflow: unset;
  }
  ${down('sm')} {
    height: fit-content;
    overflow: unset;
    padding: 69px 0px 10px 0px;
    margin: 0px 10px 14px 10px !important;
    box-shadow: 0px 4px 4px ${(props) => props.theme.colors.gray15}};
    border-radius: 0px 0px 5px 5px;
  }
  margin-top: 40px;
`;

const HeaderImage = styled.div`
  ${(props) =>
    !props.backgroundImage &&
    `background-color: ${props.theme.colors.white18};`}
  ${(props) =>
    props.backgroundImage &&
    `background-image: url(${
      props.backgroundImage.startsWith('http')
        ? props.backgroundImage
        : `/images${props.backgroundImage}`
    });`}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  margin-left: -250px;
  animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  z-index: 0;
  transition: all 0.5s ease-out;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  box-sizing: border-box;
  margin-top: -50px;
  // use the md context to implemement hte below bracket
  ${down('md')} {
    margin-top: -65px;
    padding-right: 30px;
    padding-top: 40px;
  }
  img,
  h5 {
    display: flex;
    max-width: 500px;
    height: auto;
    align-self: center;
  }
  h5 {
    width: 100%;
    display: block;
    text-align: right;
    margin-top: 10px;
  }
  img {
    max-width: 650px;
  }
  a {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.red4};
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const HeaderMonthlySponsor = styled.div`
  background-color: ${(props) => props.theme.colors.white18};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: calc(100% + 31px);
  background-size: 55%;
  width: 125%;
  margin-left: -219px;
  margin-top: -31px;
  ${down('xl')} {
    margin-left: -85px;
  }
  ${down('lg')} {
    margin-left: -30px;
  }
  ${down('sm')} {
    display: none;
  }
  animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  z-index: 0;
  transition: all 0.5s ease-out;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Image = styled.img`
  max-height: 500px;
`;
const Url = styled.a`
  letter-spacing: 2px;
`;
const UrlMeta = styled.h5`
  color: ${(props) => props.theme.colors.black10};
  font-size: 13px;
  margin-bottom: 40px;
  margin-top: 10px;
`;

const HeaderContent = styled(Container)`
  background: ${(props) => props.theme.colors.yellow4};
  position: relative;
  height: 100%;
  width: 70%;
  display: flex;
  color: ${(props) => props.theme.colors.black11};
  border-bottom-left-radius: 3px;
  z-index: 2;
  ${down('md')} {
    padding-bottom: 40px;
  }
  ${down('sm')} {
    width: 100%;
    height: calc(100% + 41px);
    background: ${(props) => props.theme.colors.yellow3};
  }
`;

const HeaderContentPlatform = styled(HeaderContent)`
  width: 73.17%;
  ${down('md')} {
    padding-bottom: 0px;
    width: 60%;
  }
  ${down('sm')} {
    width: 100%;
    background: ${(props) => props.theme.colors.white};
    padding: 0px;
  }
`;

const Navigation = styled(Nav)`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 195px;
  left: 0px;
  @media screen and (max-width: 823px) {
    bottom: 195px;
  }
`;

const NavigationPlatform = styled(Navigation)`
  width: auto;
  bottom: 0px;
  ${up('sm')} {
    nav {
      display: block;
    }
  }
  ${down('md')} {
    li {
      span {
        padding-left: 18px;
        padding-right: 18px;
      }
    }
  }
`;

const ComponentsContainer = styled(Container)`
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 450px;
  padding-left: 44px;
  box-sizing: border-box;
  ${down('md')} {
    padding-left: 25px;
    padding-right: 20px;
  }
  ${down('sm')} {
    width: 100%;
    height: auto;
  }
`;

const ComponentsContainerPlatform = styled(ComponentsContainer)`
  width: auto;
  padding-left: 14px;
  ${up('sm')} {
      justify-content: flex-end;
      margin-top: -16px;
      margin-right: 5px;
    }
  }
  ${down('md')} {
    padding-left: 14px;
  }
  ${down('sm')} {
    padding-left: 0px;
    width: 100%;
    display: ${(props) => (props.hideInMobile ? 'none' : 'inherit')}
  }
`;

const MonthlySponsorContainer = styled.div`
    display: flex;
    height: auto;
    align-self: center;
}
`;

export default function HeaderView(props) {
  const { platformApp } = useContext(AppContext);

  const { showNavigation = false } = props;
  return (
    <>
      {!platformApp && (
        <HeaderContainer id="header" className={props.className} {...props}>
          <HeaderContent className="HeaderContent">
            <ComponentsContainer className="ComponentsContainer">
              {props.content}
            </ComponentsContainer>
          </HeaderContent>
          <HeaderImage
            {...props}
            backgroundImage={props.backgroundImage}
            className="HeaderImage"
          >
            <Image alt="logo" src={props.image} />
            <UrlMeta>
              <Url target="_blank" href={props.url}>
                {props.urltitle}
              </Url>
              {props.urlmeta}
            </UrlMeta>
          </HeaderImage>
          {showNavigation ? <Navigation>{props.nav}</Navigation> : null}
        </HeaderContainer>
      )}
      {platformApp && (
        <HeaderContainerPlatform
          id="header"
          platformApp={platformApp}
          {...props}
          className={`${props.className} HeaderContainerPlatform`}
        >
          <HeaderContentPlatform className="HeaderContent">
            <ComponentsContainerPlatform>
              {props.content}
            </ComponentsContainerPlatform>
            {showNavigation ? (
              <ComponentsContainerPlatform hideInMobile>
                <NavigationPlatform>{props.nav}</NavigationPlatform>
              </ComponentsContainerPlatform>
            ) : null}
          </HeaderContentPlatform>
          <HeaderMonthlySponsor>
            <MonthlySponsorContainer>
              <div />
            </MonthlySponsorContainer>
          </HeaderMonthlySponsor>
        </HeaderContainerPlatform>
      )}
    </>
  );
}
