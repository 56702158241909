import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from 'AppContext';
import styled from 'styled-components';
import { Nav } from 'components/base/nav';
import Advisors from 'components/pages/about/advisors';
import PartnerBanner from 'components/pages/about/partnerbanner';
import Signups from 'components/pages/about/signup';
import Team from 'components/pages/about/team';
import Header from 'components/pages/homepage/homepageheader';
import Content from 'components/utilities/components/Content';
import HorizontalTimeline from 'components/utilities/components/HorizontalTimeline';
import Slides from 'components/utilities/slides';
import { theme } from 'components/utilities/utils';
import Footer from 'components/views/FooterView';
import ProgramSection from 'components/views/ProgramSection';
import Subheader from 'components/views/Subheader';
import FaqComponent from 'components/views/faq';

const Page = styled.section`
  text-align: center;
  padding: 0px;
  background: ${(props) => props.theme.colors.white};
  max-width: 1280px;
`;

const Partners = styled(PartnerBanner)`
  // padding: 0px 0 40px 0;
`;

export default function Project1464() {
  const { content } = useContext(AppContext);
  const { programs } = useContext(AppContext);
  const page = 'C1464Project';

  return (
    <Page>
      <Helmet>
        <title>Parkdale Centre for Innovation</title>
        <meta
          name="description"
          content="Grow your idea, join a community, get traction, and see your project through at Parkdale Centre"
        />
        <meta property="og:title" content="Parkdale Centre" />
        <meta property="og:image" content="images/previews/incubators.png" />
        <meta
          property="og:url"
          content="https://www.parkdaleinnovates.org/programs"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Incubate your Startup, Business or Career at Parkdale Centre."
        />
        <meta
          property="og:description"
          content="Grow your idea, get traction, and scale your work at Parkdale Centre. You bring the motivation."
        />
        <meta property="og:image" content="images/previews/incubators.png" />
      </Helmet>
      <Header
        pageName={page}
        color={
          content.programs.Headers['Header text background color'] ||
          theme.colors.purple4
        }
        nav={<Nav />}
        showNavigation
        showLanguageSelector
      />
      <Subheader
        pageName={page}
        section="SubHeaders"
        lcolor2="red"
        rcolor1={theme.colors.white}
        borderleft={theme.colors.gray5}
        borderright="transparent"
      />
      <Slides pageName={page} section="Carousel1" size="joint" />
      <HorizontalTimeline
        pageName={page}
        listName="Timeline"
        titleName="Hero"
      />
      <Content content={programs.c1464}>
        <ProgramSection
          color={content.programs.Footer.ThemeColor}
          programName={['c14643']}
          pageName={page}
        />
      </Content>
      <Team id="team" pageName="Team" />
      <Advisors
        id="advisors"
        folder="advisors"
        pageName="Advisors"
        title="Program Advisors"
        margin="40px auto 0px"
      />
      <Partners
        id="partners"
        title="With Support From"
        pageName={page}
        margin="0px auto 0px"
      />
      <FaqComponent pageName={page} />
      <Signups pageName={page} />
      <Footer pageName={page} />
    </Page>
  );
}
