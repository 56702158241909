import styled from 'styled-components';

export const SubHeading = styled.h3`
  color: ${(props) => props.color};
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-top: 40px;
  line-height: 30px;
  max-width: 768px;
  margin-bottom: 120px;
  padding: 20px;
  &:after {
    content: '';
    background-image: url('${(props) => props.image}');
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    height: 80px;
    width: 80px;
    display: block;
    position: relative;
    top: 0px;
  }
`;

export function SectionSubHeading(props) {
  const { subtitle } = props;

  return <SubHeading {...props}>{subtitle}</SubHeading>;
}
