/* eslint-disable camelcase */

/* eslint-disable no-console */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, uniqBy } from 'lodash';
import { createTheme, down } from 'styled-breakpoints';
import styled from 'styled-components';
import { supabase } from 'supabaseClient';
import { DEFAULT_LANGUAGE, LOCALES } from 'constants/locales';
import backupPrograms from './backupPrograms.json';
import { getContentsFromGoogle } from './gsapi';
import BackupContentEn from './translations/backupContent.en.json';
import BackupContentFr from './translations/backupContent.fr.json';
import BackupPortraitsEn from './translations/backupPortraits.en.json';

export const theme = {
  colors: {
    white: 'white',
    white2: '#0a0b18e6',
    white3: '#fafafa',
    white4: '#f1f1f1',
    white5: '#f7f7f7',
    white6: '#eaeaea',
    white7: '#fbfbfb',
    white8: '#ededed',
    white9: '#f2f2f2',
    white10: '#e4e4e4',
    white11: '#e2e2e2',
    white12: '#dedede',
    white13: '#cacaca',
    white14: '#11025bdd',
    white15: '#00000033',
    white16: '#d2d2d2',
    white17: '#ffffffee',
    white18: '#f8f8f8',
    white19: '#fffffff2',
    white20: '#d7d7d7',
    white21: '#dbdbdb',
    white22: '#e6e6e6',
    white23: '#bdc3c7',
    white24: '#efefef',
    white25: '#D8D8D8',
    white26: '#010101bb',
    white27: '#000000cc',
    white28: '#00000024',
    white29: '#0000001f',
    white30: '#cecece40',
    white31: '#00000026',
    white32: '#cbcbcb',
    black: 'black',
    black2: '#080913',
    black3: '#2d2d2d',
    black4: '#101010',
    black5: 'black',
    black6: '#2c2c2c',
    black7: '#060405',
    black8: '#5b5b5b',
    black9: '#000000de',
    black10: '#585858',
    black11: '#06071a',
    black12: '#373a47',
    black13: '#3e3e3e',
    black14: '#000621',
    black15: '#040404',
    black16: '#d3d3d340',
    black17: '#0000004d',
    black18: '#282D38',
    black19: '#767676',
    black20: '#131313',
    pink: '#EB008B',
    pink2: '#FFB3DB',
    pink3: '#ff506e',
    purple: '#652C90',
    purple2: '#282d38aa',
    purple3: '#11025b',
    purple4: '#3213cc',
    purple5: '#ff7575dd',
    purple6: '#C7C8FF',
    purple7: '#a919a9',
    orange: '#F16522',
    orange2: '#fdc10e',
    orange3: '#f1b500',
    orange4: '#FF8B53',
    yellow: '#282D38',
    yellow2: '#ffd700',
    yellow3: '#f3f3f3cc',
    yellow4: '#f3f3f3dd',
    yellow5: '#ffcc00',
    yellow6: '#ffc800',
    yellow7: '#f7f7f7cc',
    yellow8: '#ffffffcc',
    aqua: '#50E3C2',
    aqua2: '#007cff',
    red: '#FF1B00',
    red2: '#ff2424',
    red3: '#d90000',
    red4: 'black',
    red5: '#ff7575',
    red6: '#c30000',
    blue: '#0076FF',
    blue2: '#0055b0',
    green: '#A9E1D4',
    gray: '#e7e7e7',
    gray2: '#ccc',
    gray3: '#a2a2a2',
    gray4: '#5d5d5d',
    gray5: '#7c7c7c',
    gray6: '#dddddd',
    gray7: '#e1e1e1',
    gray8: '#939393',
    gray9: '#cfcfcf',
    gray10: '#cbcbcb',
    gray11: '#00000029',
    gray12: '#959595',
    gray13: '#d0d0d040',
    gray14: '#919191',
    gray15: '#bcbcbc80',
    gray16: '#dadada40',
    gray17: '#f5f5f5',
    gray18: '#d9d9d9',
    gray19: '#81818140',
    gray20: '#dadada80',
    coral: 'coral',
  },
};

export const breakpoints = createTheme({
  sm: '640px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
});

// Bordered List Item
export const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  height: fit-content;
  padding: 80px 40px 0px;
  background: ${(props) => props.theme.colors.white};
  flex-grow: 1 1 25%;
  border: 1px solid ${(props) => props.theme.colors.gray7};
  ${down('sm')} {
    width: 100%;
    padding: 80px 40px 0px;
  }
`;

export const ImageorIcon = styled.div`
  display: flex;
  height: fit-content;
`;

export const Image = styled.div`
  width: 200px;
  flex-wrap: wrap;
  min-height: 100px;
  display: flex;
  background: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0px;
`;

export const Icon = styled(FontAwesomeIcon)`
  min-height: initial;
  color: ${(props) => props.theme.colors.black};
  height: 100%;
  width: 30px;
  position: relative;
  margin-left: auto;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 5px;
  color: ${(props) => props.color};
`;

export const NoImageorIcon = styled.h5`
  /* display: none; */
`;

export const Subtitle = styled.div`
  display: flex;
`;

export const Title = styled.h5`
  display: flex;
  margin: 0px;
`;

export const Paragraph = styled.p`
  display: flex;
  font-size: 14px;
  line-height: 18 px;
  font-family: pt sans;
  word-break: break-word;
`;

export const Meta = styled.div`
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  p {
    word-break: break-word;
  }
`;

export const Register = styled.div`
  text-decoration: underline;
  margin-top: 10px;
`;

export const ItemLink = styled.a`
  margin-top: 10px;
`;

const Bar = styled.div`
  margin: 0 auto;
  width: 100%;
  background: ${(props) => props.theme.colors.yellow5};
  margin: 150px 20px -40px;
  padding: 20px 0px 10px;
  ${down('md')} {
    margin: 60px 0px -40px;
  }
`;

const BarContents = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

export function FullBar(props) {
  return (
    <Bar {...props}>
      <BarContents {...props} className="content">
        {props.children}
      </BarContents>
    </Bar>
  );
}

// eslint-disable-next-line consistent-return
const getContentsFromDB = async () => {
  try {
    const { data: site_content } = await supabase
      .from('site_content')
      .select('*')
      .order('timestamp', { ascending: false });
    /* Here we will remove filter when we support more language */
    return site_content.length
      ? uniqBy(site_content, 'language').filter(
          (item) => item.language === 'en' || item.language === 'fr',
        )
      : [];
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(error.message);
  }
};

export const getPageContents = async () => {
  const environment = process.env.REACT_APP_ENV;
  // console.log(environment);

  const allContents =
    environment === 'production'
      ? await getContentsFromDB()
      : await getContentsFromGoogle();
  // console.log('allContents', allContents);
  if (isEmpty(allContents)) {
    const backupContents = [
      {
        language: 'en',
        content: BackupContentEn,
        programs: backupPrograms,
        portraits: BackupPortraitsEn,
      },
      {
        language: 'fr',
        content: BackupContentFr,
        programs: backupPrograms,
        portraits: BackupPortraitsEn,
      },
    ];
    return backupContents;
  }
  return allContents;
};

export const getLocaleLink = (locale = DEFAULT_LANGUAGE, link = '') =>
  `/${locale}${link}`;

export const getLanguage = () => {
  if (
    window.location.pathname.split('/')[1] &&
    LOCALES.includes(window.location.pathname.split('/')[1])
  ) {
    return window.location.pathname.split('/')[1];
  }
  if (localStorage.getItem('locale')) {
    return localStorage.getItem('locale');
  }
  return DEFAULT_LANGUAGE;
};

export const isIntNumber = (num) => num % 1 === 0;
