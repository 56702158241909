/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filter } from 'lodash';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'constants/swiper';
import PostList from 'components/pages/blog/postlist';
import { BannerButton } from './BannerButton';

const BannerShowContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5500;
  max-width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: red;
  display: ${(props) => (props.bannerShown ? 'block' : 'none')};
  ${down('md')} {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #f7f7f7;
    border-radius: none;
  }
`;

// const BannerHideContainer = styled.div`
//   position: fixed;
//   bottom: 60px;
//   right: 108px;
//   width: 100%;
//   box-sizing: border-box;
//   z-index: 5500;
// `;

const SwiperContainer = styled.div`
  ${up('md')} {
    display: flex;
    align-items: center;
    max-width: calc(100% - 400px);

    button {
      display: none;
    }

    .swiper-pagination {
      display: none;
    }
  }

  .swiper-pagination-bullet-active {
    background: ${(props) => props.theme.colors.red3};
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: block;
  color: ${(props) => props.theme.colors.white25};
  &:hover {
    color: ${(props) => props.theme.colors.red3};
  }
  ${down('md')} {
    display: none;
  }
`;

const BannerImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const ImageBox = styled.div`
  width: 62px;
  height: 59px;

  ${up('md')} {
    margin-right: 13px;
    width: 50px;
    height: 47px;
    min-width: 50px;
    min-height: 47px;
    margin-bottom: 0;
  }
`;

const BannerInnerContainer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 1px -1px 22px 2px ${(props) => props.theme.colors.gray13};
  box-sizing: border-box;
  display: block;
  transition: display, 0.2s;
  * {
    box-sizing: border-box;
  }
  padding: 21px 27px 23px 27px;
  ${up('md')} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 10px !important;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  ${down('md')} {
    margin-bottom: 10px;
    white-space: unset;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  ${up('md')} {
    margin-right: 21px;
  }
`;

const LinkBannerButton = styled(BannerButton)`
  background-color: ${(props) => props.theme.colors.black};
  padding: 13px 21px 12px 16px;
  color: ${(props) => props.theme.colors.white};
  margin-right: 7px;
  ${down('md')} {
    margin-bottom: 8px;
  }
  &:hover,
  &:active {
    background: ${(props) => props.theme.colors.red6};
    cursor: pointer;
    transition: background, 0.2s;
  }
`;

const HideBannerButton = styled(BannerButton)`
  background-color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.gray13};
  padding: 13px 17px 12px 17px;
  ${down('md')} {
    // margin-bottom: calc(27px + 8px);
  }
  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.black};
    cursor: pointer;
    transition: background, 0.2s;
  }
`;

const ShowBannerButton = styled(HideBannerButton)`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black9};
  box-shadow: 0px 0px 5px 0px #82828240;
  position: fixed;
  bottom: 15px;
  font-size: 14px;
  right: 15px;
  &:hover,
  width: fit-content;
  &:active {
    background: black;
    color: white;
    cursor: pointer;
    transition: background, 0.2s;
  }
  // under md bottom: -20px;
  ${down('md')} {
    bottom: 15px;
  }
`;

const CloseBannerButton = styled(BannerButton)`
  background: ${(props) => props.theme.colors.gray13};
  color: ${(props) => props.theme.colors.black};
  padding-top: 13px;
  padding-bottom: 9px;
  box-sizing: border-box;
  height: 46px !important;
  ${down('md')} {
    // width: 100%;
    padding: 0px 20px;
  }

  ${up('md')} {
    padding-left: 16px;
    padding-right: 16px;
  }

  &:hover,
  &:active {
    cursor: pointer;
  }
`;

const BannerBoldText = styled.span`
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: ${(props) => props.theme.colors.black};
  margin-right: 4px;

  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  white-space: nowrap;

  @media screen and (max-width: 640px) {
    margin-bottom: -10px;
  }
`;

const BannerNormalText = styled.span`
  * {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: ${(props) => props.theme.colors.black};
    display: inline;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ImageAndTextContainer = styled.div`
  ${up('md')} {
    display: flex;
    align-items: center;
  }
`;

const AnnouncementModal = styled(Modal)`
  width: 100%;
  background: white;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.white};
  padding-top: 63px;
  margin-top: 30px;
  max-width: 1280px;
  ${up('md')} {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
  transform: translateX(-50%);
  left: 50%;
  box-sizing: border-box;
  box-shadow: 0px 0px 34px 3px ${(props) => props.theme.colors.gray};
`;

const AnnouncementDesktopContainer = styled.div`
  display: block;
  ${down('md')} {
    display: none;
  }
`;

const AnnouncementMobileContainer = styled.div`
  display: none;
  ${down('md')} {
    display: flex;
    button: first-child {
      height: fit-content;
    }
  }
`;

const ButtonsDesktopContainer = styled.div`
  display: flex;

  ${down('md')} {
    display: none;
  }
`;

const Posts = styled(PostList)``;

const PostCardContainer = styled.div`
  padding-top: 60px;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  margin: 10px 0 0;
  position: relative;
  ${down('md')} {
    padding: 0px 0px 0px;
    max-width: 100%;
  }
`;

export default function BottomBanner() {
  const initialBannerState =
    sessionStorage.getItem('isBannerShown') !== 'false';
  const [isBannerShown, setIsBannerShown] = useState(initialBannerState);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [announcements, setAnnouncements] = useState(() => []);
  const [selectedPost, setSelectedPost] = useState(() => {});
  const [selectedPostIdx, setSelectedPostIdx] = useState(0);
  const location = useLocation();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const modalRef = useRef(null);
  const swiperRef = useRef();
  const pathName = location?.pathname?.split('/');
  const pageName = pathName[2]
    ? pathName[location.pathname.split('/').length - 1]?.toLowerCase()
    : '';

  const tags = useMemo(
    () => filter(['announcement', pageName], (o) => o),
    [pageName],
  );

  const handleChangePost = () => {
    modalRef.current?.scrollIntoView();
    setSelectedPost(announcements[selectedPostIdx]);
  };

  // Fetch Announcement data for banner
  const getAnnouncementData = useCallback(async () => {
    setIsLoading(true);
    try {
      let internalPosts = [];

      const stateAnnouncements = await Promise.all(
        tags.map(async (tag) => {
          const resp = await fetch(
            `${process.env.REACT_APP_WORDPRESS_URL}/posts/${
              `?tag=${tag}` || '?number=10'
            }`,
          );
          return resp.json();
        }),
      );

      stateAnnouncements?.forEach((announcement) => {
        if (announcement.error) return;

        announcement.posts?.forEach((post) => {
          const postCategories = Object.keys(post.categories);
          const postTags = Object.keys(post.tags);

          /// reformat the post
          const tempPost = {
            id: post.ID,
            content: post.content,
            excerpt: post.excerpt,
            date: post.modified,
            sticky: post.sticky,
            title: post.title,
            image: post.featured_image || '/images/logo-full.png',
            categories: [...postCategories],
            tags: [...postTags],
            author: post.author.name,
            avatar: post.author.avatar_URL,
          };
          internalPosts = [...internalPosts, tempPost];
        });
      });

      setAnnouncements(internalPosts);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [tags]);

  const buttonText = useMemo(
    () =>
      !pageName
        ? 'Open Announcements'
        : // : `Open ${pageName.toLowerCase()} article`,
          `Open`,
    [pageName],
  );

  useEffect(() => {
    sessionStorage.setItem('isBannerShown', isBannerShown);
  }, [isBannerShown]);

  useEffect(() => {
    setIsModalShown(false);
  }, [location]);

  useEffect(() => {
    getAnnouncementData();
  }, [getAnnouncementData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedPost]);

  return (
    <>
      {!isLoading && (
        <>
          <BannerShowContainer bannerShown={isBannerShown}>
            <BannerInnerContainer>
              {' '}
              <Icon
                ref={nextRef}
                icon={faAngleLeft}
                style={{ paddingRight: 34 }}
                onClick={handleChangePost}
              />
              <SwiperContainer>
                <Swiper
                  enabled={isBannerShown}
                  effect="fade"
                  navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                  }}
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  onSwiper={(swiper) => {
                    setTimeout(() => {
                      /* eslint-disable no-param-reassign */
                      if (swiper.params && swiper.params.navigation) {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                      }

                      if (swiper.navigation) {
                        swiper.navigation.destroy();
                        swiper.navigation.init();
                        swiper.navigation.update();
                      }
                    });
                  }}
                  onSlideChange={(swiper) => {
                    setSelectedPostIdx(swiper.realIndex);
                  }}
                  autoplay={{
                    delay: 1000 * 10,
                    disableOnInteraction: false,
                  }}
                  modules={[EffectFade, Autoplay, Pagination, Navigation]}
                  loop
                  fadeEffect={{
                    crossFade: true,
                  }}
                  // pagination={{
                  //   clickable: true,
                  //   dynamicBullets: true,
                  // }}
                >
                  {announcements?.map((announcement) => (
                    <SwiperSlide key={announcement.id}>
                      <ImageAndTextContainer>
                        <ImageBox>
                          <BannerImage
                            src={announcement.avatar}
                            alt="author avatar"
                          />
                        </ImageBox>
                        <TextContainer>
                          <BannerBoldText>{announcement.title}.</BannerBoldText>
                          <BannerNormalText
                            dangerouslySetInnerHTML={{
                              __html: announcement.excerpt,
                            }}
                          />
                        </TextContainer>
                      </ImageAndTextContainer>
                    </SwiperSlide>
                  ))}
                  {!isModalShown && (
                    <>
                      <LinkBannerButton
                        onClick={() => {
                          setIsModalShown(true);
                          // setIsBannerShown(false);
                        }}
                      >
                        {buttonText}
                      </LinkBannerButton>
                      <HideBannerButton onClick={() => setIsBannerShown(false)}>
                        Hide
                      </HideBannerButton>
                    </>
                  )}
                  {isModalShown && (
                    <AnnouncementMobileContainer>
                      <LinkBannerButton
                        onClick={() => {
                          swiperRef.current?.slideNext();
                          setSelectedPost(
                            announcements[swiperRef.current.realIndex],
                          );
                        }}
                      >
                        Next Announcement
                      </LinkBannerButton>
                      <CloseBannerButton
                        onClick={() => {
                          setIsModalShown(false);
                        }}
                      >
                        Close
                      </CloseBannerButton>
                    </AnnouncementMobileContainer>
                  )}
                </Swiper>
              </SwiperContainer>
              {!isModalShown && (
                <ButtonsDesktopContainer>
                  <LinkBannerButton
                    onClick={() => {
                      setIsModalShown(true);
                    }}
                  >
                    {buttonText}
                  </LinkBannerButton>
                  <HideBannerButton onClick={() => setIsBannerShown(false)}>
                    Hide
                  </HideBannerButton>
                </ButtonsDesktopContainer>
              )}
              {isModalShown && (
                <AnnouncementDesktopContainer>
                  <CloseBannerButton
                    onClick={() => {
                      setIsModalShown(false);
                    }}
                  >
                    Close Announcements
                  </CloseBannerButton>
                </AnnouncementDesktopContainer>
              )}
              <Icon
                style={{ paddingLeft: 34 }}
                ref={prevRef}
                icon={faAngleRight}
                onClick={handleChangePost}
              />
            </BannerInnerContainer>
          </BannerShowContainer>
          {!isBannerShown && (
            <ShowBannerButton
              onClick={() => {
                setIsBannerShown(true);
              }}
            >
              Show Announcements?
            </ShowBannerButton>
          )}
        </>
      )}

      {isModalShown && (
        <AnnouncementModal
          style={{
            overlay: {
              background: 'white',
              paddingTop: 63,
            },
            content: {
              padding: 0,
            },
          }}
          isOpen={isModalShown}
          shouldCloseOnOverlayClick
          onRequestClose={() => {
            setIsModalShown(false);
          }}
          ariaHideApp={false}
        >
          <PostCardContainer ref={modalRef}>
            <Posts tags={tags} selectedPost={selectedPost} isModal />
          </PostCardContainer>
        </AnnouncementModal>
      )}
    </>
  );
}
