/* eslint-disable react/destructuring-assignment */
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const UtilButton = styled.button`
  background: ${(props) => props.background || props.theme.colors.purple3};
  font-size: ${(props) => props.fontSize || '16px'};
  font-family: pt sans;
  font-weight: 600;
  letter-spacing: 2px;
  width: fit-content;
  margin-top: 10px;
  margin-right: 5px;
  padding: ${(props) => props.padding || '10px 20px'};
  justify-content: center;
  float: ${(props) => props.float};
  border-radius: ${(props) => props.borderRadius || '0px'};
  border: 3px solid ${(props) => props.textcolor};
  color: ${(props) => props.textcolor || 'white'};
  &:hover,
  &:active {
    background: ${(props) => props.background};
    border: 3px solid ${(props) => props.background};
    cursor: pointer;
    transition: background, 0.2s;
    &.outline {
      background: ${(props) => props.textcolor};
      color: ${(props) => props.theme.colors.white};
    }
  }
  &.secondary {
    background: transparent;
    border: 2px solid ${(props) => `${props.background}dd`};
    color: ${(props) => `${props.background}dd`};
    transition: background, 0.2s;
    &:hover,
    &:active {
      background: ${(props) => props.background};
      border: 2px solid ${(props) => props.background};
      color: ${(props) => props.theme.colors.white};
      cursor: pointer;
    }
  }
`;

export function Button(props) {
  if (props.link?.externalLink) {
    return (
      <a
        target="_blank"
        href={props.link?.externalLink}
        rel="noopener noreferrer"
      >
        {' '}
        <UtilButton {...props}>{props.text}</UtilButton>
      </a>
    );
  }

  if (props.link) {
    return (
      <Link to={props.link}>
        <UtilButton {...props}>{props.text}</UtilButton>
      </Link>
    );
  }
  return <UtilButton {...props}>{props.text}</UtilButton>;
}
