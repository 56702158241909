import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints/index';
import styled from 'styled-components';

const Title = styled.span``;

const FiltersContainer = styled.div``;

const CategoryFilters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  box-sizing: border-box;
  width: 260px;
  background: ${(props) => props.theme.colors.white5};
  padding: 40px 20px;
  border-radius: 3px;
  position: sticky;
  top: 120px;
  z-index: 5;
  max-width: 300px;
  ${down('lg')} {
    width: fit-content;
    max-width: none;
    padding: 20px 20px 15px;
    margin: 20px;
    display: none;
    background: black;
    justify-content: left;
  }
`;

const CatFilter = styled.span`
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize !important;
  color: ${(props) => props.theme.colors.white} !important;
  display: flex;
  height: 20px;
  padding: 5px 12px 3px;
  color: ${(props) => props.theme.colors.black3} !important;
  width: fit-content;
  background: ${(props) => props.theme.colors.white} !important;
  &:hover,
  &.selected {
    background-color: ${(props) => props.theme.colors.yellow2} !important;
    cursor: pointer;
  }
`;

function Control({ selectedFilter, setSelectedFilter, ...props }) {
  const { title, elements, queryKey } = props;
  const navigate = useNavigate();
  const queryParams = useLocation().search;
  const { language } = useContext(AppContext);

  const applyQuery = (param, val) => {
    const currentParams = new URLSearchParams(queryParams);

    if (!val) {
      currentParams.delete(param);
    } else if (currentParams.get(param)) {
      /// this avoids duplicates (category=Freelance&category=Community)
      currentParams.delete(param);
      currentParams.append(param, val);
    } else {
      currentParams.append(param, val);
    }

    navigate({
      pathname: `/${language}/blog`,
      search: currentParams.toString(),
      hash: 'postslist',
    });
    setSelectedFilter(val);
    console.log('selectedFilter', selectedFilter);
  };

  return (
    <>
      <Title>{title}</Title>
      <CatFilter
        onClick={() => applyQuery(queryKey)}
        className={selectedFilter === null ? 'selected' : ''}
      >
        All
      </CatFilter>
      {console.log(elements)}
      {elements.map((element, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <CatFilter
          key={index}
          onClick={() => applyQuery(queryKey, element)}
          className={selectedFilter === element ? 'selected' : ''}
        >
          {element}
        </CatFilter>
      ))}
    </>
  );
}

export default function Filters({
  setSelectedFilter,
  selectedFilter,
  resetTrigger,
}) {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    async function fetchTags() {
      const allTags = new Set();
      const URL = `${process.env.REACT_APP_WORDPRESS_URL}/tags`;
      const response = await fetch(URL);
      const jsonResponse = await response.json();
      jsonResponse.tags.forEach((oneTag) => {
        allTags.add(oneTag.name);
      });
      setTags([...allTags]);
    }
    fetchTags();
    if (resetTrigger) {
      setSelectedFilter(null); // Reset the selected filter when resetTrigger changes
    }
  }, [resetTrigger, setSelectedFilter]);

  return (
    <FiltersContainer className="FiltersContainer">
      <CategoryFilters className="CategoryFilters">
        <Control
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
          className="Control"
          title=""
          elements={tags}
          queryKey="tag"
        />
      </CategoryFilters>
    </FiltersContainer>
  );
}
