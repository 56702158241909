import styled from 'styled-components';

const Button = styled.button`
  border-radius: 2px;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 2px;
  border: none;
  cursor: pointer;
  min-width: max-content;
  text-transform: capitalize;
`;

export function BannerButton({ children, className, onClick }) {
  return (
    <Button className={className} onClick={onClick}>
      {children}
    </Button>
  );
}
