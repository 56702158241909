import styled from 'styled-components';

const View = styled.div`
  display: flex;
  position: relative;
  max-width: 100%;
`;

export function Container(props) {
  // const underlined = this.props.stylizedtext
  // const secondarycontents = <div>{secondarytext} <u>{underlined}</u></div>
  return <View {...props} />;
}
