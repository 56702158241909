/* eslint-disable no-console */
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppContext from 'AppContext';
import { filter } from 'lodash';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import Filters from 'components/pages/blog/filters';
import { Button } from 'components/utilities/components/Button';
import { theme } from 'components/utilities/utils';
import PostCard from './PostCard';

const BlogRoll = styled.div`
  display: flex;
  flex-direction: row;
  ${down('lg')} {
    flex-direction: column;
  }
`;

const List = styled.div`
  max-width: 100%;
  content: '';
  margin: 0 auto;
  border-left: 1px solid ${(props) => props.theme.colors.white5};
  margin-left: -3px;
  margin-top: -80px;
  padding-top: 10px;
  padding-left: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 800px;
  margin-bottom: 60px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 250px;
  height: 100%;
`;

const ListItem = styled.div`
  box-sizing: ${(props) => !props.isModal && 'border-box'};
  width: 100%;
  border-radius: 0px;
  display: flex;
  padding: 40px 20px;
  background: ${(props) => props.theme.colors.white};
  position: relative;
  flex-wrap: wrap;
  border: 3px solid transparent;
  flex-direction: row-reverse;
  border-bottom: 5px solid #fbfbfb;
  border-right: 1px solid ${(props) => props.theme.colors.white5};
  &:hover {
    background: #fbfbfb;
  }
  &:first-of-type {
    padding-top: 60px;
    border-right: none;
    .content-container {
    }
    &:hover {
    }
  }
  &:last-of-type {
    border-bottom: none;
  }

  ${down('lg')} {
    border-radius: 10px;
    /* padding: 30px 20px; */
    flex-direction: column-reverse;
    .Stream {
      margin-top: 20px;
    }
  }
  ${down('sm')} {
    padding: 30px 5px;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  margin: 40px 30px 90px 40px;
  text-align: left;
  ${down('lg')} {
    display: none;
  }
`;

export default function PostList(props) {
  const [posts, setPosts] = useState([]);
  const { tags, postId, showAllButton, selectedPost, isModal } = props;
  const [selectedFilter, setSelectedFilter] = useState(null);
  const queryParams = useLocation().search;
  const navigate = useNavigate();
  const { language, content } = useContext(AppContext);

  const [resetTrigger, setResetTrigger] = useState(false);
  const resetFilters = () => {
    // ... other reset logic if needed
    setSelectedFilter(null);
    setResetTrigger((prev) => !prev); // Toggle the resetTrigger state
  };

  useEffect(() => {
    async function preload() {
      let internalPosts = [];

      if (tags?.length > 0) {
        try {
          const stateAnnouncements = await Promise.all(
            tags.map(async (tag) => {
              const resp = await fetch(
                `${process.env.REACT_APP_WORDPRESS_URL}/posts/${
                  `?tag=${tag}` || '?number=10'
                }`,
              );
              return resp.json();
            }),
          );
          stateAnnouncements.forEach((announcement) => {
            if (announcement.error) return;

            announcement.posts.forEach((post) => {
              const postCategories = Object.keys(post.categories);
              const postTags = Object.keys(post.tags);

              /// reformat the post
              const tempPost = {
                id: post.ID,
                content: post.content,
                excerpt: post.excerpt,
                date: post.date,
                sticky: post.sticky,
                title: post.title,
                image: post.featured_image,
                categories: [...postCategories],
                tags: [...postTags],
                author: post.author.name,
                avatar: post.author.avatar_URL,
              };
              internalPosts = [...internalPosts, tempPost];
            });
          });
        } catch (error) {
          console.error(error);
        }
      }

      if (!tags) {
        const URL = `${process.env.REACT_APP_WORDPRESS_URL}/posts/${
          postId || queryParams || '?number=10'
        }`;

        const response = await fetch(URL);
        const jsonResponse = await response.json();

        if (jsonResponse.error) return;

        const WPPosts = jsonResponse.posts
          ? jsonResponse.posts
          : [{ ...jsonResponse }];

        WPPosts.forEach((post) => {
          const postCategories = Object.keys(post.categories);
          const postTags = Object.keys(post.tags);

          /// reformat the post
          const tempPost = {
            id: post.ID,
            content: post.content,
            excerpt: post.excerpt,
            date: post.date,
            sticky: post.sticky,
            title: post.title,
            image: post.featured_image,
            categories: [...postCategories],
            tags: [...postTags],
            author: post.author.name,
            avatar: post.author.avatar_URL,
          };
          internalPosts = [...internalPosts, tempPost];
        });
      }

      setPosts(internalPosts);
    }
    preload();
  }, [queryParams, postId, tags]);

  const showAllPosts = (e) => {
    e.preventDefault();

    navigate({
      pathname: `/${language}/blog`,
      search: 'number=100',
      hash: 'postslist',
    });
  };

  const getPosts = useMemo(() => {
    if (!posts) {
      return [];
    }
    if (posts.length > 0 && selectedPost) {
      return [
        ...filter(posts, (o) => o.id === selectedPost.id),
        ...filter(posts, (o) => o.id !== selectedPost.id),
      ];
    }
    return posts;
  }, [selectedPost, posts]);

  return (
    <BlogRoll className="BlogRoll" {...props}>
      <FiltersContainer>
        <Filters
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          resetTrigger={resetTrigger}
          className="Filters"
        />{' '}
        {showAllButton && (
          <ButtonContainer>
            <Button
              textcolor={theme.colors.orange2}
              background={theme.colors.white}
              className="outline"
              text={content.blog.Actions.action1}
              onClick={(e) => {
                showAllPosts(e);
                resetFilters();
              }}
            />
          </ButtonContainer>
        )}
      </FiltersContainer>
      <List className="List" isModal={isModal}>
        {getPosts.map((post) => (
          <ListItem isModal={isModal} key={post?.id}>
            <PostCard post={post} single={!!postId} isExpanded={false} />
          </ListItem>
        ))}
      </List>
    </BlogRoll>
  );
}
