function sortEvents(events) {
  return events
    .sort((a, b) => {
      const dateA = new Date(a.start.local);
      const dateB = new Date(b.start.local);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      // return 0;

      return new Date(a.start.local);
      // return new Date(a.start.local) - new Date(b.start.local);
    })
    .sort((a) => (a.status === 'live' ? -1 : 1));
}

export async function getEvents() {
  try {
    const response = await fetch(
      // "https://www.eventbriteapi.com/v3/organizers/16805735391/events/?token=Y7USPGPSL2TWC7Y3U2PL&order_by=created_desc"
      'https://www.eventbriteapi.com/v3/organizers/16805735391/events/?token=D5EDOVHJFRN2HOO22HIU&order_by=created_desc',
      // "https://www.eventbriteapi.com/v3/organizers/16805735391/events/?token=D5EDOVHJFRN2HOO22H&order_by=created_desc"
    );
    const jsonResponse = await response.json();

    if (!jsonResponse.events) return [];

    const liveEvents = jsonResponse.events.filter(
      (item) => item.status === 'live',
    );

    return sortEvents(liveEvents);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return [];
  }
}
