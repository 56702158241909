import { useContext, useMemo } from 'react';
import AppContext from 'AppContext';
import Profile from 'components/base/profile/profile';

export default function Advisors(props) {
  const { portraits } = useContext(AppContext);
  const { pageName, id, padding, margin, title } = props;
  const biographies = useMemo(() => {
    const portraitsObj = portraits[pageName];

    if (!portraitsObj) return [];

    const result = [];
    const itemNumber = parseInt(Object.keys(portraitsObj).length / 4, 10);

    for (let index = 1; index <= itemNumber; index++) {
      if (!portraitsObj[`name${index}`]) break;
      if (!portraitsObj[`description${index}`]) break;

      result.push({
        image: portraitsObj[`image${index}`],
        name: portraitsObj[`name${index}`],
        description: portraitsObj[`description${index}`],
        linkedin: portraitsObj[`linkedin${index}`],
      });
    }

    return result;
  }, [portraits, pageName]);

  if (!biographies.length) return null;

  return (
    <Profile
      className={id}
      padding={padding}
      margin={margin}
      heading={{
        title,
      }}
      list={{
        padding: '30px 40px',
        width: 'fit-content',
        padding650Px: '20px 10px',
      }}
      withReveal={{
        distance: '10px',
        fraction: 0.1,
      }}
      content={biographies}
      type="small"
    />
  );
}
