import { useContext, useEffect, useState } from 'react';
import Faq from 'react-faq-component';
import AppContext from 'AppContext';
import styled from 'styled-components';
import Content from 'components/utilities/components/Content';

const Section = styled.section`
  max-width: 1200px;
  margin-bottom: 0px;
  padding: 0px 0px 70px;
  @media screen and (max-width: 780px) {
    padding: 20px;
  }
  .faq-body {
    padding-bottom: 60px;
  }
  .faq-row {
    margin-bottom: 10px;
    &:hover {
      .row-title {
        border-left: 3px solid ${(props) => props.theme.colors.black2};
      }
      .row-title-text {
        color: ${(props) => props.theme.colors.orange3};
      }
    }
  }
`;

function FaqComponent(props) {
  const { content } = useContext(AppContext);
  const { pageName } = props;
  const pageObject = content[pageName];
  const pageTitle = pageObject.NAV.PageName || pageName;

  const [data, setData] = useState({ title: `${pageTitle} FAQs` });

  const faqObject = content[pageName];

  useEffect(() => {
    const populateData = (faqContent) => {
      const newData = { title: `${pageTitle} FAQs` };
      const dataRows = [];
      if (faqContent) {
        for (let index = 0; index < Object.keys(faqContent).length; index++) {
          const element = Object.keys(faqContent)[index];
          if (element && element.includes('Q') && faqContent[element]) {
            dataRows.push({
              title: faqContent[element],
              content: faqContent[`A${element.slice(1, element.length)}`],
            });
          }
        }
      }
      newData.rows = dataRows;
      setData(newData);
    };

    populateData(faqObject?.Faq);
  }, [faqObject.Faq, pageTitle]);

  return (
    <Content content={data?.rows}>
      <Section id="faqs">
        <Faq id="faq" data={data} />
      </Section>
    </Content>
  );
}

export default FaqComponent;
