import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

const customFetch = async (url, options) => fetch(url, options);

const supabaseOptions = {
  fetch: customFetch,
};

// Create the supabase client with custom options
export const supabase = createClient(supabaseUrl, supabaseKey, supabaseOptions);
