import { useContext } from 'react';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Nav } from 'components/base/nav';
import PartnerBanner from 'components/pages/about/partnerbanner';
import Header from 'components/pages/homepage/homepageheader';
import Content from 'components/utilities/components/Content';
import HorizontalTimeline from 'components/utilities/components/HorizontalTimeline';
import Slides from 'components/utilities/slides';
import { theme } from 'components/utilities/utils';
import Footer from 'components/views/FooterView';
import ProgramSection from 'components/views/ProgramSection';
import FaqComponent from 'components/views/faq';
import WorkshopsView from 'components/views/workshopsview';
import Signups from './signup';

const Section = styled.section`
  text-align: center;
  padding: 0px;
  background: ${(props) => props.theme.colors.white};
  max-width: 1280px;
`;

const Programs = styled(ProgramSection)``;

const Partners = styled(PartnerBanner)`
  padding-top: 70px;
  ${down('md')} {
    padding-top: 40px;
  }
`;

export default function GetInvolvedPage() {
  const { content, programs } = useContext(AppContext);
  const page = 'getinvolved';
  return (
    <Section>
      <Header
        id="header"
        pageName={page}
        color={
          content.gettingstarted.Headers['Header text background color'] ||
          theme.colors.white26
        }
        nav={<Nav />}
        showNavigation
        showLanguageSelector
      />
      <HorizontalTimeline
        pageName={page}
        listName="Timeline"
        titleName="Hero"
      />
      <WorkshopsView />
      <Slides pageName={page} section="Carousel1" size="joint" />
      <Content content={programs.startups}>
        <ProgramSection
          color={content.programs.Footer.ThemeColor}
          programName={['ecosystem2', 'ecosystem1']}
        />
      </Content>
      <Partners id="partners" title="In Collaboration With" pageName={page} />
      <Programs
        id="programs"
        color={content.gettingstarted.Footer.ThemeColor}
        width="initial"
        programName="joinus"
      />
      <FaqComponent pageName={page} />
      <Signups pageName={page} />
      <Footer pageName={page} />
    </Section>
  );
}
