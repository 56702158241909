import styled from 'styled-components';

const SingleTag = styled.span`
  padding: 5px;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 100px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.white} !important;
  display: flex;
  height: 20px;
  padding: 3px 10px;
  width: fit-content;
  &:nth-child(5n + 0) {
    background: ${(props) => props.theme.colors.yellow};
    color: ${(props) => props.theme.colors.black} !important;
  }
  &:nth-child(5n + 1) {
    background: ${(props) => props.theme.colors.blue};
  }
  &:nth-child(5n + 2) {
    background: ${(props) => props.theme.colors.pink};
  }
  &:nth-child(5n + 3) {
    background: ${(props) => props.theme.colors.orange};
  }
  &:nth-child(5n + 4) {
    background: ${(props) => props.theme.colors.purple};
  }
`;

export function Tag(props) {
  return <SingleTag className="tag" {...props} />;
}
