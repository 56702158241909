import { useState } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Tag } from 'components/utilities/components/SingleTag';
import PostCardContent from './PostCardContent';

// force commit

const Post = withReveal(
  styled.div`
    display: flex;
    flex-direction: row-reverse;
    ${down('xl')} {
      flex-direction: column-reverse;
    }
  `,
  <Fade distance="0%" fraction={0} />,
);

const Stream = styled.div`
  max-width: 280px;
  min-width: 280px;
  height: fit-content;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
  position: relative;
  border-radius: 0px;
  margin: 0px;
  padding: 0px 0px 40px 15px;
  border-left: 1px solid ${(props) => props.theme.colors.white5};
  ${down('xl')} {
    width: 100%;
    margin-top: 40px;
    margin-left: 5px;
    border-left: none;
  }
  ${down('md')} {
    width: 80%;
    margin: 0 auto;
    margin-left: 20px;
  }
  &.inModal {
    margin: 0px;
    border-radius: 2px;
    max-width: 100%;
    width: initial;
    padding-bottom: 40px;
  }
`;

const StreamImage = styled.img`
  display: flex;
  width: 100%;
  border-radius: 0px;
  display: none;
  ${down('md')} {
    margin-left: -18px;
  }
  &.short {
    width: 180px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  ${down('md')} {
    margin: 10px -20px 5px;
  }
`;

const ProgramTag = styled(Tag)`
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize !important;
  color: ${(props) => props.theme.colors.white} !important;
  display: flex;
  height: 20px;
  padding: 8px 12px 3px;
  background: ${(props) => props.theme.colors.white5} !important;
  color: ${(props) => props.theme.colors.black3} !important;
  border-radius: 0px;
`;

const ScheduleHeading = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 20px;
  position: relative;
  text-align: left;
  top: 10px;
  color: ${(props) => props.theme.colors.black4};
  margin-top: 20px;
`;

const ProgramImage = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(${(props) => props.programimage});
  background-repeat: no-repeat;
  border: none;
  background-position-x: right;
  background-position-y: top;
  border-radius: 0px 5px 5px 5px;
  width: 100%;
  background-size: contain;
  max-height: 210px;
  max-width: 200px;
  height: 100%;
  display: none;
  &.inModal {
    height: 100%;
    width: 100%;
    position: absolute;
    max-width: 300px;
    max-height: 250px;
  }
  ${down('lg')} {
    background-size: contain;
    width: 100%;
    max-height: 300px;
    max-width: 100%;
    display: none;
    right: 0;
    border-radius: 10px 10px 0px 0px;
    &.inModal {
      z-index: 1;
    }
  }
  ${down('md')} {
    height: 300px;
    width: 100%;
    max-width: 100%;
    &.inModal {
      width: 360px;
    }
  }
  ${down('sm')} {
    height: 260px;
    width: 98%;
    &.inModal {
      top: 5px;
    }
  }
`;

export default function PostCard(props) {
  const { post, small, onTagClick, single, isExpanded } = props;
  const [expanded, setExpanded] = useState(isExpanded || single);
  const [copySuccess, setCopySuccess] = useState(false);
  const expandPost = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const sharePost = (e, postId) => {
    e.preventDefault();
    navigator.clipboard.writeText(
      `https://parkdaleinnovates.org/en/blog/${postId}`,
    );
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 5000);
  };

  return (
    <Post>
      <Stream className={small ? 'Stream inModal' : 'Stream'}>
        <StreamImage alt={post.category} src={post.image} />
        <ScheduleHeading>Tags</ScheduleHeading>
        <Tags>
          {post.tags
            ? post.tags.map((tag) => (
                <ProgramTag
                  // eslint-disable-next-line react/no-array-index-key
                  key={tag}
                  tag={tag}
                  onClick={() => onTagClick(tag)}
                >
                  {tag}
                </ProgramTag>
              ))
            : null}
        </Tags>
      </Stream>
      <PostCardContent
        post={post}
        expanded={expanded}
        expandPost={expandPost}
        sharePost={sharePost}
        small={small}
        copySuccess={copySuccess}
        single={single}
        maxWidth="620px"
      />
      <ProgramImage
        {...props}
        className={small ? 'inModal' : ''}
        programimage={post.image}
      />
    </Post>
  );
}
