import { useContext, useMemo } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { BorderedListItem } from 'components/utilities/components/BorderedListItem';
import Content from 'components/utilities/components/Content';
import { Section } from 'components/utilities/components/SectionComponent';
import { SectionHeading } from 'components/utilities/components/SectionHeading';
import { theme } from 'components/utilities/utils';

const LogoSection = styled(Section)`
  background: ${(props) => props.theme.colors.white};
  padding: 70px 0px 0px 0px;
  max-width: 1280px;
  box-sizing: border-box;
  margin: ${(props) => props.margin || 'initial'};
  margin-top: 30px;
  h2 {
    font-weight: 600;
    color: ${(props) => props.theme.colors.black2};
    margin-bottom: 0px;
  }
  ${down('sm')} {
    // padding: 0px;
  }
`;

const LogoList = withReveal(
  styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    content: 'auto';
    padding: 0px 40px 40px;
    margin: 0 auto;
    max-width: 950px;
    ${down('sm')} {
      padding: 20px 10px;
    }
  `,
  <Fade fraction={0.1} />,
);

const LogoImage = styled(BorderedListItem)`
  display: flex;
  flex-direction: column;
  height: initial;
  width: fit-content;
  padding: 20px 10px 0px;
  box-sizing: border-box;
  margin: 0px;
  margin-right: -1px;
  border: none;
  text-align: left;
  &.double {
    max-width: none !important;
    width: 100%;
    width: 490px !important;
    background-size: contain;
    ${down('sm')} {
      margin-top: 4px;
    }
    .image {
      width: 100%;
      max-width: none;
    }
  }
  h5 {
    font-weight: 600;
  }
  h6 {
    /* display: none; */
    margin-bottom: 0px;
    margin-top: 0px;
  }
  p {
    margin-top: 5px;
    font-size: 13px;
  }
  ${down('sm')} {
    margin: 0px;
    padding: 0px 10px;
    max-width: 50%;
    p {
      max-width: 80% !important;
    }
    h5 {
      max-width: 80%;
    }
    h6 {
      max-width: 80%;
    }
    .image {
      max-width: 80%;
    }
    &.widest {
      max-width: 100%;
    }
  }
  &:first-child {
    border-radius: 3px;
  }
  .image {
    background-size: contain;
    background-position-x: left;
    background-position-y: center;
    height: 100px !important;
    margin-top: -20px;
    border-radius: 2px;
    max-width: 150px;
    margin-right: 15px;
    margin-bottom: 10px;
    background-color: transparent;
    display: flex;
    align-self: center;
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-height: initial;
    max-width: initial;
    margin: 0 auto;
  }
`;

export default function PartnerBanner(props) {
  const { content } = useContext(AppContext);
  const { pageName, title } = props;
  const partnerItems = useMemo(() => {
    const result = [];
    const partnersObject = content[pageName].PartnerLogos;
    const itemNumber =
      partnersObject && parseInt(Object.keys(partnersObject).length / 3, 10);

    for (let index = 1; index <= itemNumber; index++) {
      if (!partnersObject[`image${index}`]) break;

      result.push({
        image: partnersObject[`image${index}`],
        text: partnersObject[`text${index}`],
        className: partnersObject[`className${index}`],
        width: partnersObject[`style${index}`],
      });
    }

    return result;
  }, [content, pageName]);

  return (
    <Content content={partnerItems}>
      <LogoSection {...props}>
        <SectionHeading
          {...props}
          title={title || 'Proudly Supported By'}
          background={theme.colors.white}
          maxwidth="500px"
        />
        <LogoList>
          {partnerItems.map((item, idx) => (
            <LogoImage
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              image={item.image}
              color={item.color}
              width={item.width || 'initial'}
              minWidth={item.width || '150px'}
            />
          ))}
        </LogoList>
      </LogoSection>
    </Content>
  );
}
