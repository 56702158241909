import { useContext, useMemo } from 'react';
import AppContext from 'AppContext';
import { filter, isEmpty, isNull } from 'lodash';
import moment from 'moment';
import { down, up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { Button } from 'components/utilities/components/Button';
import Content from 'components/utilities/components/Content';
import TimelineCard from 'components/utilities/components/TimelineCard';
import { theme } from 'components/utilities/utils';

const CardContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  // box-shadow: 0px 2px 3px ${(props) => props.theme.colors.white12};
  padding: ${(props) => (props.isScroll ? '80px 40px' : '80px 40px')};
  font-family: 'PT Sans';
  font-style: normal;
  text-align: left;
  position: relative;
  margin-top: 30px;
  margin-bottom: 60px;
  /* We should extend HorizontalTimeline as MetricsTimeline and handle any edits there explicilyt in an elegant way. */
  background-image: url('/images/backgrounds/centreside.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 70%;
  padding-right: 0px;
  ${down('lg')} {
    padding: 30px;
  }



  }
  ${(props) =>
    props.isScroll &&
    css`
      margin: 40px 0px 40px;
      max-width: 1156px;
      box-shadow: 0px 2px 19px ${props.theme.colors.white6};
      border-radius: 2px;
    `}
`;

const UpdatedText = styled.span`
  position: absolute;
  right: 20px;
  top: -30px;
  background: ${(props) => props.theme.colors.black5};
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white};
  padding: 10px 25px;
  border-radius: 0px 0px 3px 3px;
  border-top: 2px solid black;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 25px;

  line-height: 50px;
  color: ${(props) => props.theme.colors.black};
  margin: 0 0 15px 0;
  &:before {
    content: '';
    background-image: url('/images/rings.png');
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    height: 60px;
    width: 80px;
    display: block;
    position: absolute;
    margin-top: -5px;
    margin-left: -10px;
  }
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.black};
  margin: 0 0 20px 0;

  ${up('lg')} {
    width: 535px;
  }
`;

const TimelineCardsContainer = styled.div`
  padding: 15px;
  @media screen and (min-width: 970px) {
    display: flex;
    flex-wrap: ${(props) => (props.isScroll ? 'inherit' : 'wrap')};
    gap: 16px 20px;
    overflow-x: ${(props) => (props.isScroll ? 'auto' : 'unset')};
    scrollbar-width: thin;
  }
  ${down('sm')} {
    > div {
      width: 100% !important;
    }
  }
  transition: all, 0.2s;
`;

const TimelineCardContainer = styled.div`
  width: 100%;
  ${down('md')} {
    :not(:last-child) {
      margin-bottom: 20px;
    }
  }

  ${up('md')} {
    width: calc((100% - 20px) / 2);
    justify-content: space-between;
    display: flex;
  }

  ${up('lg')} {
    width: ${(props) => (props.isScroll ? '295px' : 'calc((100% - 40px) / 3)')};
    min-width: ${(props) =>
      props.isScroll ? '295px' : 'calc((100% - 40px) / 3)'};
    margin-bottom: ${(props) => (props.isScroll ? '15px' : '0px')};
  }
`;

const ProgramTimelineButton = styled(Button)`
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 17px;
  color: ${(props) => props.theme.colors.black};
  border-radius: 1px;
  margin-top: 45px;
  margin-left: 20px;
  transition: all 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};
    border-color: ${(props) => props.theme.colors.black};
    transition: all 0.3s;
  }
  ${up('lg')} {
    margin-left: -9px;
  }
`;

export default function HorizontalTimeline(props) {
  const { timestamp, content } = useContext(AppContext);
  const { pageName, listName, titleName, scroll } = props;
  const timelineData = content[pageName][listName];
  const heroData = content[pageName][titleName];
  const actionButton = content[pageName]?.Actions?.action1;
  const actionLink = content[pageName]?.Actions?.action1Link;

  /**
   * Function for creating object arrays sorted by number of items
   */
  const sortedTimelineData = useMemo(() => {
    if (!timelineData) return [];
    const timelineDataKeys = Object.keys(timelineData);

    // The biggest number of items.
    // Needed so that we can go through array and sort it no more that existing numbers
    const theBiggestNumber = Math.max(
      ...timelineDataKeys.map((word) => Number(word.slice(-1))),
    );

    return Array.from(Array(theBiggestNumber), (_, index) =>
      timelineDataKeys.reduce((acc, curr) => {
        if (!curr.endsWith(index + 1)) return acc;

        acc[curr.slice(0, -1)] = timelineData[curr];

        return acc;
      }, {}),
    );
  }, [timelineData]);

  return (
    <Content content={heroData}>
      <CardContainer id="HorizontalTimeline" isScroll={scroll}>
        <UpdatedText>
          Updated {moment(timestamp).format('MMMM YYYY')}
        </UpdatedText>
        <Title>{heroData?.title}</Title>
        <Subtitle>{heroData?.subtitle}</Subtitle>
        <TimelineCardsContainer isScroll={scroll}>
          {sortedTimelineData &&
            filter(
              sortedTimelineData,
              (timeline) =>
                !isEmpty(
                  filter(Object.values(timeline), (arr) => !isNull(arr)),
                ),
            ).map((item, i) => (
              <TimelineCardContainer key={i} isScroll={scroll}>
                <TimelineCard
                  item={item}
                  count={item.metric ?? i + 1}
                  fullWidth
                  isMetric={!!item.metric}
                />
              </TimelineCardContainer>
            ))}
        </TimelineCardsContainer>

        <ProgramTimelineButton
          textcolor={theme.colors.black}
          background={theme.colors.white}
          text={actionButton}
          link={actionLink}
        />
      </CardContainer>
    </Content>
  );
}
