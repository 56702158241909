import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from 'AppContext';
import styled from 'styled-components';
import { Nav } from 'components/base/nav';
import Signups from 'components/pages/about/signup';
import Header from 'components/pages/homepage/homepageheader';
import HorizontalTimeline from 'components/utilities/components/HorizontalTimeline';
import Slides from 'components/utilities/slides';
import { theme } from 'components/utilities/utils';
import Footer from 'components/views/FooterView';
import Subheader from 'components/views/Subheader';
import FaqComponent from 'components/views/faq';
import HeroView from 'components/views/heroview';
import Partners from 'components/views/partners';
import WorkshopsView from 'components/views/workshopsview';

const Home = styled.div``;

// const SliderContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   position: relative;
//   margin: 0 auto;
//   max-width: 1280px;
//   width: 100%;
// `;

const Section = styled.section`
  text-align: center;
  padding: 0px;
  background: ${(props) => props.theme.colors.white};
  max-width: 1280px;
`;

export default function HomePage() {
  const { content } = useContext(AppContext);

  return (
    <Home>
      <Helmet>
        <title>Parkdale Centre for Innovation</title>
        <meta
          name="description"
          content="Not-for-profit Committed to Inclusion & Equity in Innovation."
        />
        <meta property="og:title" content="Parkdale Centre" />
        <meta property="og:image" content="images/previews/homepage.png" />
        <meta property="og:url" content="https://www.parkdaleinnovates.org" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Parkdale Centre for Innovation." />
        <meta
          property="og:description"
          content="A part of CanadaInnovates.org."
        />
        <meta property="og:image" content="images/previews/homepage.png" />
      </Helmet>
      <Header
        pageName="homepage"
        color={
          content.homepage.Headers['Header text background color'] ||
          theme.colors.white27
        }
        nav={<Nav />}
        showNavigation
        showLanguageSelector
      />
      {/* <SliderContainer>
        <HeaderSlider />
        <LanguageSelector style={{ top: '80px' }} />
      </SliderContainer> */}
      <Section>
        <HorizontalTimeline
          pageName="homepage"
          listName="Metric"
          titleName="MetricHero"
          scroll
        />
      </Section>
      <WorkshopsView />
      <Subheader
        pageName="homepage"
        section="SubHeaders"
        lcolor2="red"
        rcolor1={theme.colors.white}
        borderleft={theme.colors.black}
        borderright="transparent"
      />
      <div id="Hero" />
      <HeroView timeline pageName="homepage" />
      <Slides pageName="homepage" section="Carousel1" size="joint big" />
      <Partners pageName="homepage" />
      <FaqComponent pageName="homepage" />
      <Signups pageName="homepage" color={theme.colors.white} />
      <Footer pageName="homepage" />
    </Home>
  );
}
