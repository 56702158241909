import { useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'AppContext';
import styled from 'styled-components';

const ListContainer = styled.div`
  border-radius: 0px;
  box-shadow: 1px 4px 4px ${(props) => props.theme.colors.white30};
  margin-left: -6px;
  width: 100%;
  max-width: 166px;

  @media screen and (max-width: 576px) {
    max-width: 100px;
  }
`;

const LanguageSelectorContainer = styled.div`
  position: absolute;
  right: 30px;
  top: ${(props) => props.top || '80px'};

  @media screen and (max-width: 768px) {
    top: ${(props) => props.mobileTop || '30px'};
    right: 20px;
  }
  z-index: 100;
`;

const LanguageSelect = styled.div`
  display: flex;
  & > *:not(#languages-select) {
    flex-shrink: 0;
  }
  & > *:not(:last-child) {
    margin-right: 7px;
  }
`;

const Button = styled.button`
  height: 30px;
  width: 30px;
  background: ${(props) =>
    props.active ? props.theme.colors.black2 : props.theme.colors.white};
  color: ${(props) =>
    props.active ? props.theme.colors.white : props.theme.colors.black};
  font-size: 12px;
  font-family: pt sans;
  font-weight: 700;
  cursor: pointer;
  border: none;
  padding: 8px;
  box-shadow: 1px 4px 4px ${(props) => props.theme.colors.white30};
  &:hover {
    transition: background, 0.2s;

    background: ${(props) => props.theme.colors.black2};
    color: ${(props) => props.theme.colors.white};
  }
`;

const List = styled.div`
  ::-webkit-scrollbar-thumb {
    border-bottom: 3px ${(props) => props.theme.colors.white} solid;
    background-clip: padding-box;
  }

  height: 30px;
  scrollbar-width: thin;
  transition: all, 0.2s;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 1px 4px 4px ${(props) => props.theme.colors.white30};
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  & > *:not(:last-child) {
    &:before {
      margin-top: 10px;
      margin-bottom: 10px;
      top: 0px;
      right: 0px;
      position: absolute;
      content: '';
      height: 12px;
      width: 1px;
      background: ${(props) => props.theme.colors.gray12};
    }
  }
`;

const ListItem = styled.div`
  padding: 8px;
  color: ${(props) => props.theme.colors.gray12};
  background: ${(props) => props.theme.colors.white};
  font-size: 12px;
  font-weight: 400;
  font-family: pt sans;
  cursor: pointer;
  position: relative;
  box-shadow: 1px 4px 4px ${(props) => props.theme.colors.white30};
  border-radius: 0px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-bottom: -4px;
`;

function LanguageSelector(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { style } = props;
  const { contents, language, setLanguage } = useContext(AppContext);

  const otherLanguages = useMemo(() => {
    const stateContents = contents?.filter(
      (content) => content.language !== 'en' && content.language !== 'fr',
    );
    return stateContents;
  }, [contents]);

  const [showLanguages, setshowLanguages] = useState(false);

  const handleLanguage = (locale) => {
    setLanguage(locale);
    localStorage.setItem('locale', locale);
    // eslint-disable-next-line no-unused-vars
    const [_, ...path] = location.pathname.split('/').filter((p) => p.length);
    if (path?.length) {
      navigate(`/${locale}/${path.join('/')}`);
    } else {
      navigate(`/${locale}`);
    }
  };

  return (
    <LanguageSelectorContainer mobileTop={style.mobileTop} top={style.top}>
      <LanguageSelect>
        {showLanguages && (
          <ListContainer id="languages-select">
            <List>
              {otherLanguages.map((content) => (
                <ListItem
                  onClick={() => {
                    handleLanguage(content.language);
                    setshowLanguages(false);
                  }}
                  key={content.language}
                >
                  {content.language.toUpperCase()}
                </ListItem>
              ))}
            </List>
          </ListContainer>
        )}
        {otherLanguages.length > 0 && (
          <Button
            aria-describedby="languages-select"
            active={showLanguages}
            type="button"
            onClick={() => {
              setshowLanguages(!showLanguages);
            }}
          >
            <Icon icon={faEllipsis} />
          </Button>
        )}
        <Button
          type="button"
          active={language === 'fr'}
          onClick={() => {
            handleLanguage('fr');
          }}
        >
          FR
        </Button>
        <Button
          type="button"
          active={language === 'en'}
          onClick={() => {
            handleLanguage('en');
          }}
        >
          EN
        </Button>
      </LanguageSelect>
    </LanguageSelectorContainer>
  );
}

export default LanguageSelector;
