import { useContext, useMemo, useState } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import AppContext from 'AppContext';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Container } from 'components/base/view';
import GroupDropdown from 'components/pages/homepage/homepagedropdown';
import Content from 'components/utilities/components/Content';
import LanguageSelector from 'components/utilities/components/LanguageSelector';
import Logo from 'components/utilities/logo';
import HeaderView from 'components/views/headerview';

const HomePageHeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  background: white;
  ${(props) => props.platformApp && `max-width: 100%;`}

  ${down('md')} {
    max-width: 100vw;
    overflow: hidden;
  }
`;

const HomepageHeader = styled(HeaderView)`
  /* We should import this into platforms' own component and file and override it there instead */
  margin: ${(props) => (props.platformApp ? '66px' : '100px')} auto 0px;
  ${down('sm')} {
    padding-bottom: 40px;
    margin: 0px;
    padding-left: 0px;
  }
`;

const ContainerContents = withReveal(
  styled.div`
    display: flex;
    width: 100%;
    ${up('sm')} {
      width: 100%;
    }
    max-width: 768px;
    height: 100%;
    flex-direction: column;
    & > a {
      text-align: left;
    }
  `,
  <Fade cascade right distance="10%" fraction={0.2} />,
);

const ContainerContentsPlatform = styled(ContainerContents)`
  width: 100%;
  ${down('sm')} {
    flex-direction: row;
    height: 56px;
    .react-reveal {
      width: calc(100% - 147px);
      background: ${(props) => props.theme.colors.gray17};
    }
  }
`;

const Component1 = styled(Container)`
  width: 82px;
  height: 70px;
  z-index: 2;
  display: flex;
  margin-top: 120px;
  margin-bottom: 20px;
`;

const Component2 = withReveal(
  styled(Container)`
    opacity: 0.95;
    box-sizing: border-box;
    text-align: left;
    font-weight: 600;
    padding: 10px 60px 0px 0px;
    visibility: visible;
    opacity: 0.9;
    z-index: 1;
    font-weight: 900;
    letter-spacing: -0.55px;
    font-family: pt serif;
    font-size: 29px;
    line-height: 41px;
    margin-bottom: 20px;
    max-width: 600px;
    ${down('sm')} {
      margin-bottom: 20px;
      font-family: 'pt serif';
      font-size: 29px;
    }
  `,
  <Fade cascade right distance="10%" fraction={0.2} />,
);

const Component2Platform = styled(Component2)`
  font-weight: 700;
  letter-spacing: 0.55px;
  max-width: 690px;
  font-family: 'Roboto';
  font-size: 21px;
  padding: 0px;
  margin-top: 10px;
  margin-left: 12px;
  margin-bottom: 0px;
  padding: 15px 0px 5px;
  ${down('md')} {
    font-size: 18px;
  }
  ${down('sm')} {
    font-family: 'Roboto';
    font-size: 16px;
    padding: 7.5px 0px 7.5px 15px;
    padding: 0px 0px 0px 5px;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-transform: capitalize;
    }
  }
`;

const SubHeading = styled.span`
  font-size: 18px;
  font-family: pt sans;
  text-align: left;
  width: fit-content;
  color: ${(props) => props.theme.colors.black5};
  background: ${(props) => props.theme.colors.white};
  padding: 10px 25px 10px 15px;
  border-left: 3px solid ${(props) => props.theme.colors.black};
  margin-top: 4px;
  margin-left: -2px;
  border-radius: 1px 3px 3px 1px;
  display: inline-block;
  // border: 3px solid black;
  :hover {
    // background-color: ${(props) => props.theme.colors.black5};
    // color: ${(props) => props.theme.colors.white};
    cursor: default;
  }
  ${down('lg')} {
    // margin-right: 20px;
  }
  ${down('md')} {
    margin-right: 0px;
  }
  ${down('sm')} {
    margin-bottom: 10px;
    border-left: 3px solid ${(props) => props.theme.colors.black};
  }
`;

const SubHeadingPlatform = styled.div`
  ${up('sm')} {
    box-sizing: border-box;
    width: 100%;
    margin-left: 0px;
    height: 45px;
  }

  ${down('sm')} {
    margin: auto 0;
    width: 147px;
    background: ${(props) => props.theme.colors.gray17};
    padding: 31px 0px 8px 0px;
    margin-top: -23px;
  }
`;

const Welcome = styled.span`
  display: none;
`;

export default function Header(props) {
  const { content, platformApp, isMobile } = useContext(AppContext);
  const { pageName, showNavigation, showLanguageSelector } = props;
  const [open, setOpen] = useState(false);

  const name = 'Rusul';

  const headerObject = content[pageName];

  const headerText = useMemo(() => {
    if (platformApp) {
      if (pageName === 'homepage') {
        return `Welcome back, ${name}!`;
      }
      return isMobile ? pageName : `Viewing ${pageName}`;
    }
    return '';
  }, [pageName, platformApp, isMobile]);

  return (
    // TODO removed platformApp once we have gsapi
    <Content content={headerObject?.Headers || platformApp}>
      <HomePageHeaderContainer
        platformApp={platformApp}
        id="TopHeaderContainer"
      >
        <HomepageHeader
          {...props}
          platformApp={platformApp}
          className={props.headertype}
          image={
            headerObject?.Headers.Image.includes('http')
              ? headerObject?.Headers.Image
              : `/images${headerObject?.Headers.Image}`
          }
          backgroundImage={headerObject?.Headers.BackgroundImage}
          url={headerObject?.Headers.ImageUrl}
          urltitle={headerObject?.Headers.ImageUrlText}
          urlmeta={headerObject?.Headers.ImageCaption}
          showNavigation={showNavigation}
          content={
            platformApp ? (
              <ContainerContentsPlatform>
                <Component2Platform>
                  <Welcome>{headerText}</Welcome>
                </Component2Platform>
                <SubHeadingPlatform>
                  <GroupDropdown
                    options={[]}
                    handleOnChange={() => {}}
                    value="My Company"
                    handleClick={() => setOpen(!open)}
                    open={open}
                    handleOnClose={() => setOpen(false)}
                  />
                </SubHeadingPlatform>
              </ContainerContentsPlatform>
            ) : (
              <ContainerContents>
                <Component1>
                  <Logo
                    pageicon={
                      headerObject?.NAV?.Image?.includes('http')
                        ? headerObject?.NAV?.Image
                        : `/images${headerObject?.NAV?.Image}`
                    }
                  />
                </Component1>
                <Component2>{headerObject?.Headers.HeaderText}</Component2>
                <SubHeading>{headerObject?.Headers.SubheaderText}</SubHeading>
              </ContainerContents>
            )
          }
        />
        {showLanguageSelector && <LanguageSelector style={{ top: '80px' }} />}
      </HomePageHeaderContainer>
    </Content>
  );
}
