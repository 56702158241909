import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from 'AppContext';
import moment from 'moment';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Container } from 'components/base/view';
import { Button } from 'components/utilities/components/Button';
import Content from 'components/utilities/components/Content';
import { Section } from 'components/utilities/components/SectionComponent';
import { theme } from 'components/utilities/utils';
import CustomizedTimeline from 'components/views/timeline';

const HeroContainer = styled(Section)`
  position: relative;
  display: flex;
  flex: 2;
  max-width: 1280px;
  margin: 0 auto;
  background: url(/images/backgrounds/squares.png);
  background-size: 95%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${(props) => props.theme.colors.white5};
  box-sizing: border-box;
  margin: 180px auto 0px;
  overflow: visible;
  padding-bottom: 90px;
  ${down('md')} {
    margin-top: 60px;
  }
`;

const LeftImage = styled.div`
  background: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  float: left;
  width: 40%;
  margin-right: -50px;
  margin-left: -40px;
  background-position-y: 130px;
  ${down('lg')} {
    background-position-x: -90px;
    display: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 40px;
    background-position-y: initial;
  }
  ${down('sm')} {
    top: 0px;
  }
`;

const InfoSection = styled(Container)`
  background: ${(props) => props.theme.colors.white19};
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-top: -140px;
  padding: 0px 40px 50px;
  font-family: pt sans;
  box-shadow: -3px -1px 15px -3px rgba(219, 219, 219, 0.75);
  -webkit-box-shadow: -3px -1px 15px -3px rgba(219, 219, 219, 0.75);
  -moz-box-shadow: -3px -1px 15px -3px rgba(219, 219, 219, 0.75);
  min-width: 580px;
  @media screen and (max-width: 945px) {
    min-width: initial;
    flex: 10;
    margin: 70px auto 0px;
    margin-top: 70px;
    padding: 10px 0px 40px 0px;
    width: 100%;
  }
`;

const InfoContent = styled.div`
  padding: 70px 60px 50px 50px;
  border-radius: 2px;
  text-align: left;
  white-space: normal;
  ${down('sm')} {
    max-width: initial;
    padding: 40px 20px !important;
  }
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 50px;
  position: relative;
  z-index: 1;
  margin-top: 0px;
  margin-bottom: 15px;
  width: fit-content;
  ${down('sm')} {
    width: fit-content;
  }
  &:before {
    content: '';
    background-image: url('/images/rings.png');
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    height: 60px;
    width: 100%;
    display: block;
    position: absolute;
    margin-top: -5px;
    margin-left: -10px;
  }
`;

const SubTitle = styled.div`
  font-size: 21px;
  line-height: 28px;
  width: 95%;
  white-space: normal;
  word-wrap: break-word;
`;

const LastUpdated = styled.div`
  position: absolute;
  top: 0px;
  right: 30px;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  padding: 8px 25px 10px;
  font-size: 16px;
  text-transform: uppercase;
  border-top: 3px solid ${(props) => props.theme.colors.black};
`;

const HeroButton = styled(Button)`
  margin-top: 4px;
  ${down('lg')} {
    margin-left: 40px;
  }
`;

export default function HeroView(props) {
  const { timestamp, content, language } = useContext(AppContext);
  const { pageName } = props;

  const navigate = useNavigate();

  return (
    <Content content={content[pageName].Hero}>
      <HeroContainer {...props}>
        <LeftImage {...props} image={content[pageName].Hero.image} />
        <InfoSection>
          <InfoContent>
            <LastUpdated>
              Updated {moment(timestamp).format('MMMM YYYY')}
            </LastUpdated>
            <Title>{content[pageName].Hero.title}</Title>
            <SubTitle>{content[pageName].Hero.subtitle}</SubTitle>
          </InfoContent>
          {props.timeline && <CustomizedTimeline {...props} />}
          <HeroButton
            fontSize="17px"
            letterSpacing="1px"
            className="outline"
            textcolor={theme.colors.black}
            background={theme.colors.white}
            text="See Programs"
            onClick={() => {
              navigate(`/${language}/programs/earlystagestartups`);
            }}
          />
        </InfoSection>
      </HeroContainer>
    </Content>
  );
}
