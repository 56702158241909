/* eslint-disable react/destructuring-assignment */
import { useMemo } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  Container,
  Icon,
  Image,
  ImageorIcon,
  ItemLink,
  Meta,
  NoImageorIcon,
  Paragraph,
  Register,
  Subtitle,
  Title,
} from '../utils';

export function BorderedListItem(props) {
  const { icon, image, className, color } = props;

  const iconComponent = useMemo(() => {
    const faType = icon?.split('-')[0];
    const faName = icon?.substring(icon.indexOf('-') + 1);

    if (icon) return <Icon color={color} icon={[faType, faName]} />;
    if (image) return <Image className={`${className} image`} src={image} />;

    return <NoImageorIcon />;
  }, [image, icon, className, color]);

  return (
    <Container
      className={`item-container ${props.className}`}
      status={props.status}
      onClick={props.onClick}
    >
      <ImageorIcon>{iconComponent}</ImageorIcon>
      <Meta className="meta-data">
        {props.subtitle && (
          <Subtitle>
            <LinesEllipsis
              text={props.subtitle}
              maxLine="2"
              ellipsis="..."
              trimRight
              basedOn="words"
              component="h6"
            />
          </Subtitle>
        )}
        <Title>{props.title}</Title>

        <Paragraph>{props.paragraph}</Paragraph>
        <ItemLink href={props.link}>{props.linkText}</ItemLink>
        <Register className="register">
          <span>{props.register}</span>
        </Register>
      </Meta>
    </Container>
  );
}
