import { useMemo } from 'react';
import { filter, isEmpty } from 'lodash';

function Content(props) {
  const { content, children } = props;

  const value = useMemo(() => {
    // Case 1: If 'content' is a boolean
    if (typeof content === 'boolean') {
      // Directly return the boolean value of 'content'.
      // This means if 'content' is true, 'value' will be true, and vice versa.
      return content;
    }

    // Case 2: If 'content' is an object (and not null)
    if (content && typeof content === 'object') {
      // Filter the values of the object to include only truthy values.
      // Then check if the filtered result is not empty.
      // If the object has at least one truthy value, 'value' will be true.
      return !isEmpty(filter(Object.values(content), (o) => o));
    }

    // Case 3: For all other types of 'content' (like string, number, array, etc.)
    // Check if 'content' is not empty.
    // If 'content' is not an empty string, zero, null, undefined, or an empty array,
    // 'value' will be true.
    return !isEmpty(content);
  }, [content]);

  // Render 'children' only if 'value' is true.
  // This means 'children' will be rendered only if 'content' is either a true boolean,
  // a non-empty object with at least one truthy value, or any other non-empty value.
  return value && children;
}

export default Content;
