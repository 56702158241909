import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from 'AppContext';
import styled from 'styled-components';
import { Nav } from 'components/base/nav';
import Signups from 'components/pages/about/signup';
import Header from 'components/pages/homepage/homepageheader';
import HorizontalTimeline from 'components/utilities/components/HorizontalTimeline';
import { theme } from 'components/utilities/utils';
import Footer from 'components/views/FooterView';
import Subheader from 'components/views/Subheader';
import FaqComponent from 'components/views/faq';

const Section = styled.section`
  text-align: center;
  padding: 0px;
  max-width: 1280px;
`;

// const ProgramsContainer = styled.div`
//   background: ${(props) => props.theme.colors.black7};
//   padding: 10px 20px 100px;
//   box-sizing: border-box;
//   margin: 0px auto;
//   position: relative;
//   border-left: 5px solid ${(props) => props.theme.colors.yellow};
//   ${down('sm')} {
//     padding: 0px 0px 0px;
//     max-width: 100%;
//   }
// `;

export default function Funding() {
  // const { content, programs } = useContext(AppContext);
  const { content } = useContext(AppContext);
  return (
    <Section>
      <Helmet>
        <title>Parkdale Centre for Innovation</title>
        <meta name="description" content="Media, Arts, News & Journalism" />
        <meta property="og:title" content="Parkdale Centre" />
        <meta property="og:image" content="images/previews/incubators.png" />
        <meta
          property="og:url"
          content="https://www.parkdaleinnovates.org/programs"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Media Lab, Journalism program, Podcast & video workshops, Parkdale Radio"
        />
        <meta
          property="og:description"
          content="Grow your idea, get traction, and scale your work at Parkdale Centre. You bring the motivation."
        />
        <meta property="og:image" content="images/previews/incubators.png" />
      </Helmet>
      <Header
        pageName="funding"
        color={
          content.funding.Headers['Header text background color'] ||
          theme.colors.white27
        }
        nav={<Nav />}
        showNavigation
        showLanguageSelector
      />

      <Subheader
        pageName="funding"
        section="SubHeaders"
        lcolor2="red"
        rcolor1={theme.colors.white}
        borderleft={theme.colors.red5}
        borderright="transparent"
      />
      <HorizontalTimeline
        pageName="funding"
        listName="Metric"
        titleName="MetricHero"
      />

      {/* <Content content={programs.startupfund || programs.mediafund}>
        
          <ProgramSection
            color={content.funding.Footer.ThemeColor}
            programName="startupfund"
          />
          <ProgramSection
            color={content.funding.Footer.ThemeColor}
            programName="mediafund"
          />
        
      </Content> */}
      <FaqComponent pageName="funding" />
      <Signups pageName="funding" />
      <Footer pageName="funding" />
    </Section>
  );
}
