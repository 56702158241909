import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Nav } from 'components/base/nav';
import Advisors from 'components/pages/about/advisors';
import { Board } from 'components/pages/about/board';
import PartnerBanner from 'components/pages/about/partnerbanner';
import Signups from 'components/pages/about/signup';
import Team from 'components/pages/about/team';
import Header from 'components/pages/homepage/homepageheader';
import Slides from 'components/utilities/slides';
import { theme } from 'components/utilities/utils';
import Footer from 'components/views/FooterView';
import FaqComponent from 'components/views/faq';

const Partners = styled(PartnerBanner)`
  padding-top: 0px;
`;

const Section = styled.section`
  text-align: center;
  padding: 0px;
  max-width: 1280px;
`;

export default function AboutUsPage() {
  return (
    <Section>
      <Helmet>
        <title>Parkdale Centre for Innovation</title>
        <meta
          name="description"
          content="The People, Team, Mentors and Community behind Parkdale Centre."
        />
        <meta property="og:title" content="Parkdale Centre" />
        <meta property="og:image" content="images/previews/people.png" />
        <meta
          property="og:url"
          content="https://www.parkdaleinnovates.org/people"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Parkdale Centre for Innovation - People"
        />
        <meta
          property="og:description"
          content="The People, Team, Mentors and Community behind Parkdale Centre."
        />
        <meta property="og:image" content="images/previews/people.png" />
      </Helmet>
      <Header
        id="board"
        pageName="aboutus"
        color={theme.colors.pink}
        nav={<Nav />}
        showNavigation
        showLanguageSelector
      />
      <Board id="board" pageName="Board" />
      <Team id="team" pageName="Team" />
      <Advisors id="Advisors" title="Program Advisors" pageName="Advisors" />
      <Advisors
        id="Alumni"
        pageName="Alumni"
        title="Alumni Highlights"
        padding="0px 0px 0px"
        margin="20px auto 0px"
      />
      <Advisors
        id="Alumni2"
        pageName="Alumni2"
        title=""
        padding="0px 0px 0px"
        margin="-80px auto 60px"
      />
      <Slides pageName="aboutus" section="Carousel1" />
      <Partners
        id="partners"
        pageName="aboutus"
        title="Ecosystem Partners"
        margin="40px auto -80px"
      />
      <FaqComponent pageName="aboutus" />
      <Signups pageName="aboutus" />
      <Footer pageName="aboutus" />
    </Section>
  );
}
