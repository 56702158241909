import { useParams } from 'react-router-dom';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Nav } from 'components/base/nav';
import Signups from 'components/pages/about/signup';
import PostList from 'components/pages/blog/postlist';
import Footer from 'components/views/FooterView';
import Subheader from 'components/views/Subheader';

const Section = styled.section`
  text-align: center;
  padding: 0px;
  max-width: 1280px;
`;

const PostContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  padding: 70px 20px 10px;
  box-sizing: border-box;
  margin: 10px auto 0px;
  position: relative;
  ${down('lg')} {
    padding: 0px 0px 0px;
    max-width: 100%;
  }
`;

const ResourcesNav = styled(Nav)`
  position: relative;
  top: 560px;
  margin: 0 auto;
`;

const Signup = styled(Signups)`
  margin-bottom: 30px;
`;

export default function Blog() {
  const params = useParams();

  return (
    <Section>
      <ResourcesNav />
      <Subheader pageName="blog" section="SubHeaders" showLanguageSelector />
      <PostContainer id="postslist">
        <PostList postId={params.postId} showFilters showAllButton />
      </PostContainer>
      <Signup pageName="blog" />
      <Footer pageName="blog" />
    </Section>
  );
}
