import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { BorderedListItem } from 'components/utilities/components/BorderedListItem';
import Content from 'components/utilities/components/Content';
import { Section } from 'components/utilities/components/SectionComponent';
import { SectionSubHeading } from 'components/utilities/components/SectionSubHeading';
import { Tag } from 'components/utilities/components/SingleTag';
import { getEvents } from 'components/utilities/eventbriteapi';

const Close = styled(Tag)`
  position: absolute;
  top: 15px;
  right: 15px;
  background: ${(props) => props.theme.colors.gray6};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  &:hover {
    background: orange;
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'scroll',
  },
};

const WorkshopsContainer = styled(Section)`
  max-width: 1280px;
  position: relative;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-bottom: 40px;
  margin: 0 auto;
  margin-top: 40px;
  ${down('sm')} {
    margin-top: 0px;
  }
  ${down('sm')} {
    margin-left: 0px;
    padding-left: 0px;
  }
  b {
    @media screen and (max-width: 645px) {
      display: none;
    }
  }
`;

const WorkshopsSubHeading = styled(SectionSubHeading)`
  text-align: left;
  margin: 0px 0px 0px;
  font-size: 19px;
  border-left: 5px solid ${(props) => props.theme.colors.black};
  height: 100%;
  box-sizing: border-box;
  padding: 25px 20px 20px 45px;
  max-width: 100%;
  background: ${(props) => props.theme.colors.white9};
  font-weight: 900;
  ${down('md')} {
    padding: 30px 20px;
  }
  ::after {
    position: absolute;
    right: 0px;
    top: 80px;
    opacity: 0.4;
  }
`;

const WorkshopList = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  box-sizing: border-box;
  justify-content: flex-start;
  background: ${(props) => props.theme.colors.white};
  padding: 40px 0px;
  overflow-y: hidden;
`;

const Workshop = styled(BorderedListItem)`
  display: flex;
  max-width: 240px;
  background-size: cover;
  flex-direction: column;
  text-aling: left;
  margin: 0px;
  padding: 60px 20px 20px;
  position: relative;
  border: none;
  &:nth-child(even) {
    background-color: #f7f7f7;
  }
  .meta-data {
    padding: 0px;
    text-align: left;
  }
  .register {
    font-size: 14px;
    color: ${(props) => props.theme.colors.black14};
    text-decoration: none;
    text-transform: uppercase;
    box-sizing: border-box;
    height: 31px;
    width: 86px;
    border: 1px solid ${(props) => props.theme.colors.yellow};
    margin-bottom: 30px;
    margin-left: 13px;
    display: flex;
    align-content: center;
    justify-content: center;
    font-weight: 500;
    background: black;
    color: white;
    span {
      align-self: center;
    }
    &:hover {
      background: ${(props) => props.theme.colors.yellow6};
      border: 1px solid ${(props) => props.theme.colors.yellow6};
      color: black;
      font-weight: 600;
    }
  }
  cursor: pointer;
  transition: box-shadow 0.2s;
  &.completed {
    margin-top: 0px;
    opacity: 0.75;
    &:hover {
      cursor: none;
    }
  }
  h5 {
    display: flex;
    float: left;
    font-weight: 600;
    height: fit-content;
    width: fit-content;
    font-size: 13px;
    padding: 10px 20px;
    background: #f7f7f7;
    color: ${(props) => props.theme.colors.black} !important;
    position: absolute;
    top: 10px;
    left: 20px;
  }
  h6 {
    padding: 0px 0px 5px 14px;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0px;
    height: 20px;
    margin: 15px 0px 10px;
    padding-top: 0px;
  }
  p {
    font-weight: 400;
    font-family: pt sans;
    font-size: 13px;
    letter-spacing: 0px;
    padding: 0px 15px 5px 15px;
    margin-bottom: 0px;
  }
`;

export default function WorkshopsView() {
  const [eventbriteEvents, seteventbriteEvents] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [iframe, setIframe] = useState();

  useEffect(() => {
    async function preLoad() {
      const events = await getEvents();

      seteventbriteEvents(events);
      setIsLoaded(true);
    }

    preLoad();
  }, []);

  const loadIFrame = (eventURL) => {
    setModalIsOpen(true);
    setIframe(
      `<iframe title="iframe" width="800px" height="600px" src="${eventURL}?ref=elink"></iframe>`,
    );
  };

  return (
    <Content
      content={
        eventbriteEvents && process.env.REACT_APP_TYPE !== 'platform'
          ? eventbriteEvents
          : null
      }
    >
      <WorkshopsContainer>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Workshopview Modal"
          style={customStyles}
          ariaHideApp={false}
        >
          <Close onClick={() => setModalIsOpen(false)}>Back to Website</Close>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: iframe }} />
          {/* <div id="eventbrite-widget-container-51310223259" /> */}
        </Modal>

        {isLoaded && eventbriteEvents.length ? (
          <>
            <WorkshopsSubHeading subtitle="Workshops & Events" />
            <WorkshopList>
              {isLoaded &&
                eventbriteEvents.map((event) => (
                  <Workshop
                    key={event.id}
                    id="eventbrite-widget-modal-trigger-51310223259"
                    /// status can be "live" or "completed"
                    className={event.status}
                    image={
                      event.logo && event.logo.url
                        ? event.logo.url
                        : 'https://www.parkdaleinnovates.org/images/logo-full.png'
                    }
                    title={`${moment(event.start.local).format(
                      'MMM DD. h:mma',
                    )}`}
                    subtitle={event.name.text}
                    paragraph={event.description.text}
                    onClick={() =>
                      loadIFrame(
                        event.url ||
                          'https://www.eventbrite.ca/o/parkdale-centre-for-innovation-16805735391',
                      )
                    }
                    register="RSVP"
                    share="share"
                  />
                ))}
            </WorkshopList>
            {/* <WorkshopSignup>
              <WorkshopButtons>
                <b> All workshops are free to attend. RSVP on Eventbrite</b>
                <ModalButton
                  background={theme.colors.yellow}
                  text="Check Events"
                  modalcontent={
                    <iframe
                      title="iframe"
                      width="800px"
                      height="600px"
                      src="https://www.eventbrite.ca/o/parkdale-centre-for-innovation-16805735391"
                    />
                  }
                />
              </WorkshopButtons>
            </WorkshopSignup> */}
          </>
        ) : null}
      </WorkshopsContainer>
    </Content>
  );
}
