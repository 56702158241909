import { useContext } from 'react';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Container } from 'components/base/view';
import Content from 'components/utilities/components/Content';
import Logo from 'components/utilities/logo';
import HeaderView from 'components/views/headerview';

const FooterView = styled(HeaderView)`
  font-weight: 400;
  margin-bottom: 40px;
  ${down('sm')} {
    padding-bottom: 40px;
    margin: 0px 0px 0px;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    .HeaderImage {
      margin-left: -50px;
    }
  }
  .HeaderContent {
    ${down('sm')} {
      padding: 0px 0px 40px;
    }
  }
`;

// TODO: I'm a celebrity, get me out of hessre.
const ComponentsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 70px;
  ${down('md')} {
    padding-left: 20px;
    width: 90%;
  }
`;

const ContainerContents = styled.div`
  display: flex;
  width: 100%;
  max-width: 768px;
  height: 100%;
  flex-direction: column;
`;

const Component1 = styled(Container)`
  width: 82px;
  height: 70px;
  z-index: 2;
  display: flex;
  margin-top: 120px;
`;

const Component2 = styled(Container)`
  /* background: ${(props) => props.color}; */
  /* border-radius: 2px; */
  opacity: 0.95;
  box-sizing: border-box;
  font-size: 21px;
  text-align: left;
  line-height: 25px;
  letter-spacing: -0.55px;
  font-family: Raleway;
  font-weight: 600;
  padding: 10px 80px 0px 0px;
  visibility: visible;
  max-width: 595px;
  opacity: 0.9;
  z-index: 1;
`;

const SubHeading = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray3};
  text-align: left;
  background: ${(props) => props.theme.colors.white};
  padding: 3px 10px 2px 8px;
  margin-top: 5px;
  margin-left: -3px;
  border-radius: 2px;
  width: fit-content;
  color: ${(props) => props.theme.colors.black};
`;

const Address = styled.p`
  text-align: left;
  margin: 0px;
  width: fit-content;
  max-width: 360px;
  background: ${(props) => props.theme.colors.white};
  &:first-of-type,
  &:last-of-type {
    margin-top: 20px;
  }
  &:last-of-type {
    background: none;
    font-weight: 600;
  }
`;

export default function Footer(props) {
  const { content } = useContext(AppContext);
  const { pageName } = props;
  const footerObject = content[pageName].Footer;

  return (
    <Content content={footerObject}>
      <FooterView
        {...props}
        showNavigation
        color="transparent"
        headertype="footer"
        className={props.headertype}
        image={footerObject.Image}
        content={
          <ComponentsContainer {...props}>
            <ContainerContents>
              <Component1 {...props}>
                <Logo pageicon="/images/logo-full.png" />
              </Component1>
              <Component2 {...props}>{footerObject.Text}</Component2>
              <SubHeading {...props}>{footerObject.Subheader}</SubHeading>
              <Address>{footerObject.address1}</Address>
              <Address>{footerObject.address2}</Address>
              <Address>{footerObject.address3}</Address>
              <Address>{footerObject.note}</Address>
            </ContainerContents>
          </ComponentsContainer>
        }
      />
    </Content>
  );
}
