import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const SectionComponent = styled.section`
  background: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  max-width: 100%;
  margin-top: ${(props) => props.topMargin};
  padding: 60px 0px 40px;
  overflow-x: hidden;
  ${down('sm')} {
    padding-top: 0px;
  }
`;

export function Section(props) {
  const { children } = props;

  return (
    <SectionComponent
      {...props}
      backgroundImage={props.image}
      topMargin={props.marginTop}
    >
      {children}
    </SectionComponent>
  );
}
