import { Box } from '@mui/material';
import styled from 'styled-components';

const Heading = styled.h2`
  margin: 0 auto;
  margin-top: 0px;
  max-width: ${(props) => props.maxwidth};
  text-align: left;
  border-radius: 2px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  content: '';
  display: table;
  clear: both;
  font-size: 18px;

  &:after {
    content: '${(props) => props.title}';
    position: relative;
    padding: 20px 0px 40px;
  }
`;

export function SectionHeading(props) {
  return (
    <Box width="100%">
      <Heading
        {...props}
        title={props.title}
        background={props.background}
        color={props.color}
        maxwidth={props.maxwidth}
      />
    </Box>
  );
}
