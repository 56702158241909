import { useContext } from 'react';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import Content from 'components/utilities/components/Content';
import ProgramCard from 'components/views/ProgramCard';

const ProgramSectionContent = styled.div`
  display: flex;
  padding: 0px 0px 0px;
  padding: 20px;
  margin-bottom: 100px;
  padding-bottom: 100px;
  padding: 20px 20px 160px;
  background: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : '#f7f7f7'};
  // on md and down, remove left and right padding
  @media screen and (max-width: 1060px) {
    padding: 0px;
  }
`;

const ProgramCardList = styled.section`
  content: '';
  margin: 0 auto;
  display: flex;
  background: ${(props) => props.color};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  color: ${(props) => props.theme.colors.black};
  /* max-width: 1024px; */
  max-width: ${(props) => props.width || '1024px'};
  /* max-width: ${(props) => props.width}; */
  border-radius: 2px 2px 5px 5px;
  padding: 0px 40px 40px;
  margin-top: 150px;
  width: 100%;
  max-width: none;
  max-width: 1024px;
  box-sizing: border-box;
  background: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : 'grey'};
  background-size: contain;
  &:first-of-type {
    margin-top: 200px;
  }
  @media screen and (max-width: 1024px) {
    padding: 20px;
    border-radius: 0px;
    &:first-of-type {
      margin-top: 140px;
    }
  }
  &:last-of-type {
    margin-bottom: -40px;
  }
`;

const ListItem = styled.div`
  margin-bottom: 20px;
  border-radius: 2px;
  display: flex;
  box-shadow: 0 1px 5px ${(props) => props.theme.colors.white31};
  border-bottom: 5px solid ${(props) => props.theme.colors.gray2};
  padding: 50px 35px 50px;
  background: ${(props) => props.theme.colors.white};
  position: relative;
  flex-wrap: wrap;
  border-radius: 3px 3px 3px 3px;
  border: 3px solid transparent;
  width: 100%;
  &:first-child {
    margin-top: -100px;
    border-radius: 2px 2px 3px 3px;
  }
  ${down('lg')} {
    border-radius: 10px;
    padding: 30px 20px;
    flex-direction: column-reverse;
    margin-bottom: 40px;
    .Stream {
      margin-top: 20px;
    }
    /* &:first-of-type {
      /* margin-top: 0px; */
    /* }  */
  }
  ${down('sm')} {
    padding: 30px 5px 10px;
  }
`;

export default function ProgramSection({ programName, width, ...otherProps }) {
  const { programs } = useContext(AppContext);

  // Ensure 'programName' is always an array, even if a single name is passed
  const programNames = Array.isArray(programName) ? programName : [programName];
  const contentForPrograms = programNames
    .map((name) => programs[name])
    .filter(Boolean);
  // const backgroundImage =
  //   contentForPrograms.length > 0 ? contentForPrograms[0].backgroundImage : '';
  const backgroundImage =
    'https://lh3.ggpht.com/p/AF1QipPf92CjdBmQGQ5PfzibjiDT4TVhb_yYByBccG2J=s1024';
  return (
    <Content content={contentForPrograms}>
      <ProgramSectionContent
        className="ProgramSectionContent"
        // backgroundImage={backgroundImage}
        // Assuming 'content' should refer to the first program for some reason.
        // If this needs to change, adjust accordingly.
        content={programs[programNames[0]]}
      >
        <ProgramCardList
          className="ProgramCardList"
          width={width} // Corrected typo 'with' to 'width'
          {...otherProps}
          backgroundImage={backgroundImage}
        >
          {programs &&
            programNames.map((name) => {
              if (programs[name]) {
                return (
                  <ListItem
                    id={programs[name].hash}
                    key={name}
                    className="ProgramCardContainer"
                  >
                    <ProgramCard
                      className="ProgramCard"
                      big
                      program={programs[name]}
                    />
                  </ListItem>
                );
              }
              return null;
            })}
        </ProgramCardList>
      </ProgramSectionContent>
    </Content>
  );
}
