import { useContext, useMemo } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import 'styles.scss';
import Content from 'components/utilities/components/Content';

const Section = styled.section`
  padding: 0px;
  max-width: 1280px;
  scrollbar-width: none;
  overflow: hidden;
  z-index: 5;

  ${down('md')} {
    margin-bottom: -40px;
    padding-top: 120px !important;
  }

  .carousel-caption {
    /* min-height: 520px; */
    ${down('md')} {
      /* min-height: 420px; */
    }
  }
  &.joint {
    margin-top: 0px;
    background: ${(props) => props.theme.colors.white5};
    padding-top: 40px;
    .highlights {
      top: 40px;
    }
  }
`;

const Slide = styled(Carousel)`
  margin: 0 auto;
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  .carousel-caption {
  }
  .carousel-indicators {
    height: 42px;
    position: relative;
    width: 80px;
    left: 80px;
    width: fit-content;
    height: 40px;
    z-index: 501;
    .active {
      background-color: ${(props) => props.theme.colors.black2};
    }
    ${down('xl')} {
      left: 100px;
    }
    ${down('lg')} {
      left: 132px;
    }
    ${down('md')} {
      left: -40px;
    }
  }
  .carousel-indicators > button {
    background-color: ${(props) => props.theme.colors.white16};
    padding: 0px;
    top: -9px;
    height: 39px;
    position: relative;
    width: 80px;
    &:hover,
    .active {
      cursor: pointer;
      opacity: 1;
    }
  }
`;

const CarouselItem = styled(Carousel.Item)`
  min-height: 595px;
  height: 100%;
  padding: 80px 60px 40px;
  box-sizing: border-box;
  flex-flow: row wrap;
  margin-top: 30px;
  ${down('md')} {
    min-height: 595px;
    padding: 0px;
  }
  &.active {
    z-index: 500;
    display: flex;
  }
`;

const Image = styled.div`
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-position-x: right;
  background-repeat: no-repeat;
  height: 440px;
  width: 100%;
  position: relative;
  top: -50px;
  left: 50px;
  flex: 2;
  ${down('md')} {
    flex: initial;
    height: 290px;
    background-position-x: left;
  }
`;

const CarouselCaption = styled(Carousel.Caption)`
  flex: 2;
  text-align: right;
  left: initial;
  right: initial;
  position: relative;
  h3 {
    color: ${(props) => props.theme.colors.black};
    font-size: 15px;
    line-height: 32px;
    display: inline-block;
    padding: 60px 60px 70px;
    margin-top: 30px;
    margin-bottom: -5px;
    font-family: pt serif;
    background: ${(props) => props.theme.colors.white17};
    ${down('md')} {
      padding: 40px;
      margin-top: -80px;
    }
  }
  h4 {
    display: block;
    float: right;
    font-size: 16px;
    font-weight: 300;
    text-align: right;
    background: ${(props) => props.theme.colors.black5};
    color: ${(props) => props.theme.colors.white};
    padding: 10px 25px 10px 15px;
    margin-top: 4px;
    border-radius: 1px;
    width: fit-content;
  }
`;

const HighlightsTab = styled.div`
  position: absolute;
  top: 0px;
  left: 110px;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  padding: 8px 25px 10px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 900;
  z-index: 5000;
  ${down('md')} {
    display: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  width: 100%;
  ${down('md')} {
    flex-flow: column wrap;
  }
`;

export default function Slides(props) {
  const { content } = useContext(AppContext);
  const { pageName, section, size } = props;
  const carouselItems = useMemo(() => {
    const result = [];
    const carouselObject = content[pageName][section];
    const itemNumber =
      carouselObject && parseInt(Object.keys(carouselObject).length / 3, 10);

    for (let index = 1; index <= itemNumber; index++) {
      if (!carouselObject[`image${index}`]) break;

      result.push({
        id: index,
        image: carouselObject[`image${index}`],
        caption: carouselObject[`caption${index}`],
        meta: carouselObject[`meta${index}`],
      });
    }

    return result;
  }, [content, pageName, section]);

  return (
    <Content content={carouselItems}>
      <Section className={size}>
        <HighlightsTab className="highlights">Highlights</HighlightsTab>
        <Slide id={section} intervel={0.1}>
          {carouselItems?.map((item) => (
            <CarouselItem key={item.id}>
              <ContentContainer>
                <Image src={item.image} alt={item.caption} />
                <CarouselCaption>
                  <h3>{item.caption}</h3>
                  <h4>{item.meta}</h4>
                </CarouselCaption>
              </ContentContainer>
            </CarouselItem>
          ))}
        </Slide>
      </Section>
    </Content>
  );
}
