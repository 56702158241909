import { useContext } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { ClickAwayListener } from '@mui/material';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import Navigation from 'components/base/navigation/navigation';
import { theme } from 'components/utilities/utils';

const MobileNavSlideContainer = styled.div`
  .bm-menu-wrap {
    width: 200px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5501 !important;
    height: auto !important;

    @media screen and (max-height: 850px) {
      height: 100% !important;
      overflow-y: hidden;
      background-color: ${(props) => props.theme.colors.white};
    }
  }

  .bm-cross-button {
    &:hover {
      background: ${(props) => props.theme.colors.gray7};
      border-radius: 2px;
      .bm-cross {
        background: ${(props) => props.theme.colors.black5} !important;
      }
    }
  }

  .react-reveal {
    opacity: 1;
  }
  ${(props) =>
    props.platformApp &&
    css`
      .bm-burger-button {
        width: 69px;
        height: 60px;
        margin-left: 11px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        ${down('sm')} {
        }
      }
    `}
  ${(props) =>
    !props.isMobileNavOpen
      ? css`
          .bm-burger-button {
            background: ${props.theme.colors.black2} !important;
            color: ${props.theme.colors.white};
            font-weight: ${props.platformApp ? 700 : 900};
            font-size: ${props.platformApp ? '15px' : '17px'};
            &:hover {
              background: ${props.theme.colors.orange2} !important;
            }
            left: 10%;
            @media screen and (min-width: 1281px) {
              left: calc((100% - 1260px) / 2) !important;
              ${props.platformApp && `left: 10px !important`};
            }

            ${down('md')} {
            }
            ${down('sm')} {
              left: ${props.platformApp && `21px !important`};
              margin-left: 0px;
            }
          }
        `
      : css`
          .bm-burger-button {
            display: none;
          }
        `}
            ${(props) => props.platformApp && `left: 10px !important`};
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 90px;
  ${down('sm')} {
  }
  z-index: 1000;
  @media screen and (min-width: 1281px) {
    // left: calc((100% - 1280px) / 2 + 95px);
  }
`;

const CanadaInnovatesLogo = styled.img`
  width: 70px;
  margin-left: 10px;
`;

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    left: '22px',
    top: '0px',
    height: '18px',
    borderRadius: '0px 0px 3px 3px',
    width: '50px',
    padding: '20px 40px 25px',
    background: theme.colors.white5,
  },

  bmIcon: {
    height: '18px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bmBurgerBars: {
    background: theme.colors.black12,
  },
  bmCrossButton: {
    height: '26px',
    width: '25px',
    top: '10px',
    right: '10px',
  },
  bmCross: {
    background: theme.colors.white23,
    height: '15px',
    width: '2px',
  },
  bmMenu: {
    backgroundColor: `${theme.colors.white} !important`,
    padding: '0px',
    fontSize: '1.15em',
    top: '0px',
    position: 'absolute',
    width: '290px',
    height: 'initial',
    borderLeft: `25px solid ${theme.colors.black2}`,
    borderRadius: '0px 2px 3px 0px',
  },

  bmMorphShape: {
    fill: theme.colors.black12,
  },
  bmItemList: {
    color: theme.colors.white,
    padding: '0px',
  },
  bmMenuWrap: {
    width: '290px',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: theme.colors.white,
    display: 'none',
  },
};

const stylesPlatform = {
  ...styles,
  bmBurgerButton: {
    left: '10px',
    padding: '0px',
    top: '0px',
  },
  bmIcon: {
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export function MobileNavSlider({
  isOpen,
  setMenuOpen,
  onStateChange,
  id,
  customBurgerIcon,
}) {
  const { platformApp } = useContext(AppContext);

  return (
    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
      <MobileNavSlideContainer
        platformApp={platformApp}
        isMobileNavOpen={isOpen}
      >
        <Menu
          isOpen={isOpen}
          onStateChange={onStateChange}
          id={id}
          styles={platformApp ? stylesPlatform : styles}
          customBurgerIcon={customBurgerIcon}
        >
          <Navigation isMobileNav />
        </Menu>
        {platformApp && (
          <LogoContainer>
            <CanadaInnovatesLogo
              alt="Canada Innovates"
              src="/images/orglogo.png"
            />
          </LogoContainer>
        )}
      </MobileNavSlideContainer>
    </ClickAwayListener>
  );
}
