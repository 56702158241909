import { useContext, useMemo } from 'react';
import AppContext from 'AppContext';
import Profile from 'components/base/profile/profile';
import { theme } from 'components/utilities/utils';

export default function Team(props) {
  const { portraits } = useContext(AppContext);
  const { pageName } = props;

  const mentors = useMemo(() => {
    const mentorsObj = portraits[pageName];

    if (!mentorsObj) return [];

    const result = [];
    const itemNumber = parseInt(Object.keys(mentorsObj).length / 4, 10);

    for (let index = 1; index <= itemNumber; index++) {
      if (!mentorsObj[`name${index}`]) break;
      if (!mentorsObj[`description${index}`]) break;

      result.push({
        image: mentorsObj[`image${index}`],
        name: mentorsObj[`name${index}`],
        description: mentorsObj[`description${index}`],
        linkedin: mentorsObj[`linkedin${index}`],
      });
    }

    return result;
  }, [portraits, pageName]);

  return (
    <Profile
      padding="100px 40px 0px"
      paddingFor640Px="100px 20px 0px"
      background={theme.colors.white5}
      borderLeft={`5px solid ${theme.colors.purple2}`}
      heading={{
        title: 'Programs Team',
      }}
      list={{
        marginTop: '40px',
        padding: '20px 40px',
        padding650Px: '20px 10px',
      }}
      content={mentors}
      type="medium"
    />
  );
}
