/* eslint-disable react/destructuring-assignment */
import styled from 'styled-components';

export const Item = styled.li`
  list-style: none;
  float: ${(props) => props.float};
`;

export const ItemImage = styled.img`
  width: ${(props) => props.width} !important;
`;

export const ItemText = styled.h5`
  width: 100%;
  margin-top: 0%;
  span {
    color: ${(props) => props.color} !important;
  }
`;

export const ItemContent = styled.div`
  float: left;
  text-align: left;
  margin-left: 10px;
  max-width: 220px;
`;
export function ListItem(props) {
  return (
    <Item {...props}>
      <ItemImage alt={props.title} width={props.imageWidth} src={props.image} />
      <ItemContent className="itemcontent">
        <ItemText color={props.color}>{props.children}</ItemText>
        <p>{props.text}</p>
      </ItemContent>
    </Item>
  );
}
