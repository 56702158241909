/* eslint-disable no-console */

/* eslint-disable no-alert */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { filter, isNull } from 'lodash';
import { supabase } from 'supabaseClient';
import { getContentsFromGoogle } from 'components/utilities/gsapi';

function DeployContentV5() {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/', { state: { refresh: true } }, { replace: true });
  };

  useEffect(() => {
    async function addContent(contents) {
      const stateErrors = await Promise.all(
        contents.map(async (content) => {
          const { error } = await supabase.rpc('add_content', {
            _timestamp: new Date(),
            content: content.content,
            language: content.language,
            potraits: content.portraits,
            programs: content.programs,
          });
          return error;
        }),
      );
      return stateErrors;
    }
    async function addDBContent() {
      console.log('<==Contents fetching from sheets...==>');
      let contents = await getContentsFromGoogle();
      console.log('<==All active sheets content are fetched==>', contents);
      if (contents.length === 0) {
        alert('Contents not found!');
        navigateToHome();
        return;
      }
      contents = contents?.filter(
        (item) => item.deployStatus === 'Ready to deploy',
      );
      console.log(`<==Contents/Sheets which are ready for deploy==>`, contents);
      if (contents.length === 0) {
        alert('Contents are not ready for deploy!');
        navigateToHome();
        return;
      }
      console.log(`<==Deployment in progress...==>`);
      const errors = await addContent(contents);
      if (filter(errors, (err) => !isNull(err))?.length > 0) {
        console.log('<==Deployment failed==>');
        alert('Deployment failed');
        return;
      }
      console.log('<==Deployment Complete==>');
      alert('Deployment Complete');
      navigateToHome();
    }
    try {
      addDBContent();
    } catch (e) {
      console.log('<==Something went wrong==>', e);
      alert('Something went wrong');
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return null;
}

export default DeployContentV5;
